@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.BestPracticeCardFront{
    width: 100%;
    z-index: 1;
    border-radius: 0.25em;
    overflow: hidden;
    box-shadow: 0em 0.1em 0.25em 0em rgba(0, 0, 0, 0.5);
    &.clickable{cursor: pointer;}
    &-inner{
        position: relative;
        width: 100%;
        height: 100%;
        text-align: left;
        background-image: linear-gradient(#30ad87, #288e73);
        border-radius: 0.25em;
        color: #fff;
    }
    &-header{
        width: 100%;
        height: 2.4em;
        padding: 0 0.6em;
        background-color: #288e73;
        border-top-left-radius: 0.25em;
        border-top-right-radius: 0.25em;
        @include flex(left, center);
    }
    &-title{
        line-height: 1.2;
        font-weight: bold;
        text-transform: uppercase;
    }
    &-body{
        padding: 1em;
        min-height: 17em;
        border-bottom-left-radius: 0.25em;
        border-bottom-right-radius: 0.25em;
        @include flex(space-between);
		flex-direction:column;
    }
    &-text{
        font-size: 0.95em;
    }
    &-selectBox{
        position: relative;
        height: 2em;
        margin-top: 0.6em;
        &:after {
            content: "";
            position: absolute;
            font-size: 1em;
            left: 0;
            bottom: 0em;
            width: 1.6em;
            height: 1.6em;
            border: 0.2em solid white;
            box-sizing: border-box;
        }
        &.selected {
            &:after {
                background-image: url('../../assets/images/icon-check-slim.svg');
                background-size: 78%;
                background-position: center center;
                background-repeat: no-repeat;
            }
        }
        &.disabled {
            &:after { 
                border: 0.2em solid rgba(255,255,255,0.4);
            }
        }
    }
    &-disabledOverlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 0.25em;
		background-color: rgba(#50545A, 0.4);
		z-index: 2;
	}
}
