@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.BestPracticesBenefits {
	position: relative;
	height: 100%;
	background-image: url('../../assets/images/background2.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	color: white;

	&-header {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 5.56em;
		padding: 1em;
	}

	&-content{
		padding: 6.56em 2em 0.5em 2em;		
	}

	&-description{
		background-color: rgba(33, 54, 64, 0.5);
		padding: 1em;
		border-radius: 0.33em;
		width: calc(50vw - 0.7em);
		margin: 1em auto;
		text-align: center;
		font-weight: 600;
	}
	
	&-board{
		width: 50vw;
		margin: 1em auto;
	}

	&-title{
		color:#fff;
		text-align: center;
		font-style: italic;
		margin: 0 0 1em 0;
		font-weight: 900;
	}

	&-cardWrap{
		position: relative;
		display: inline-block;
		vertical-align: top;
		width: calc(50% - 0.7em);
		text-align: left;
		background-color: linear-gradient(rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.07));
		border-radius: 0.25em;
		z-index: 1;
		margin: 0 0.35em 0.7em 0.35em;
	}
}



