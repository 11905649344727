@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.BestPractices {
	position: relative;
	background-image: url('../../assets/images/background2.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	color: white;
	min-height: 100vh;
	&-header {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 5.56em;
		padding: 1em;
	}

	&-content{
		padding: 6.56em 2em 0.5em 2em;		
	}

	&-board{
		&.reflection{
			background-color: rgba(33, 54, 64, 0.4);
			padding: 1em;
			border-radius: 0.33em;
			.BestPractices-reflectiondescription{
				margin: 0 0 0.7em 0;
				padding: 1em;
			}
			.BestPractices-cardWrap{
				@include opacity(1);
				&.selected{
					width: calc(50% - 2em);
					margin: 0 1em 1.3em 1em;
					.BestPracticeCardFront{
						&-selectBox{
							display: none;
						} 
						&-body{
							min-height:10em;
						}
					}
				}
				&.deselected{
					@include opacity(0);
					width:0;
					height:0;
					padding: 0;
    				margin: 0;
				}
			}
			.Reflection-answer {
				width: calc(50% - 2em);
				display: inline-block;
				position: relative;
				margin: 0 1em 1.3em 1em;
				top: auto;
				right: auto;
				.Reflection-textarea {
					width: 100%;
					height: 100%;
					background-color: rgba(33, 54, 64, 0.4);
					resize: none;
					border-radius: 0.25em;
					border: 0.2em solid rgba(33, 54, 64, 0.5);
					color: #fff;
					padding: 1.2em;
					outline: none;
					scrollbar-width: thin;
					scrollbar-color: rgba(255, 255, 255, 0.25) #213640;
				}
			}
		}	
	}
	
	&-title{
		color:#fff;
		text-align: center;
		font-style: italic;
		margin: 0 0 3em 0;
		font-weight: 900;
	}

	&-cardWrap{
		position: relative;
		display: inline-block;
		vertical-align: top;
		width: calc(25% - 0.7em);
		text-align: left;
		background-image: linear-gradient(rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.07));
		border-radius: 0.25em;
		z-index: 1;
		margin: 0 0.35em 0.7em 0.35em;
	}
}



