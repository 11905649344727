@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';

.Reflection {
	position: relative;
	min-height: 100%;
	background-image: url('../../assets/images/background2.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	padding: 6em 2em 2em 2em;
	color: white;
}

.Reflection-questions {
	width: 100%;
	height: 100%;
	&:not(.safetytraining) {
		.Reflection-question--intro{
			min-height:auto;
			.Reflection-questionText{
				width:100%;
			}
		}
	}
	&.small {
		width: 75%;
		display: inline-block;
		vertical-align: top;
	}
	&.dd-reflection,
	&.focus-points,
	&.topics {
		width: calc(100% - 30.5em);
		display: inline-block;
		vertical-align: top;
		margin-right: 3.5em;
		margin-top: 1em;
		background-color: rgba(#213640, 0.4);
		border-radius: 0.33em;
		padding: 1em;
		.Reflection-question {
			background-color: transparent;
			padding: 0em;
			margin-bottom: 2em;
			&:last-child {margin-bottom: 0;}
		}
		.Reflection-questionText {
			width: 100%;
			margin-bottom: 0.5em;
			font-weight: 700;
			p {margin: 0;}
		}
		.Reflection-answer {
			position: relative;
			width: 100%;
			top: auto;
			right: auto;
			.Reflection-textarea { 
				width: 100%;
				padding: 1em;
			}
		}
	}
	&.final-reflection:not(.safetytraining) {
		.Reflection-question {
			font-size: 0.88em;
			min-height: 14.7em;
			padding: 1em 1.66em;
			margin-bottom: 1.11em;
		}
		.Reflection-question--scale{
			min-height:auto;
		}
		.Reflection-question--intro{
			min-height:auto;
			.Reflection-questionText{
				width:100%;
			}
		}
		.Reflection-questionText {
			width: 40%;
			p {margin: 0.1em;}
		}
		.Reflection-answer {
			width: calc(50% + 5.8em);
		}
	}
	&.topics {
		width: calc(100% - 32em);
		.Reflection-questionText { 
			font-weight: normal;
		}
	}
}

.Reflection-introText {
	margin-bottom: 2em;
	p {margin: 0;}
}

.Reflection-question {
	position: relative;
	width: 100%;
	min-height: 10em;
	background-color: rgba(#213640, 0.4);
	padding: 1em 1em 1em 1em;
	margin-bottom: 0.2em;
	border-radius: 0.33em;
}

.Reflection-questionText {
	position: relative;
	width: 50%;
}

.Reflection-answer {
	position: absolute;
	top: 1em;
	right: 1em;
	width: calc(50% - 1em);
	height: calc(100% - 2em);
	text-align: center;
	@include textarea-placeholder(white);
	.Reflection-textarea {
		width: 96%;
		height: 100%;
		background-color: rgba($black-seeThrough, 0.4);
		resize: none;
		border-radius: 0.25em;
		border: 0.2em solid rgba($black, 0.5);
		color: $white;
		padding: 1.2em;
		outline: none;
		@include custom-scrollbar(0.5em, $black, $white-seeThrough);
	}
}

.Reflection-threatCards {
	display: inline-block;
	vertical-align: top;
	width: 13em;
	margin-left: 2em;
	.Reflection-threatsTitle {
		border-radius: 0.25em 0.25em 0 0;
		width: 100%;
		height: 2em;
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 700;
		font-style: italic;
		background-color: $black;
		color: $yellow;
		
	}
	.Reflection-threatCard {
		height: 2.5em;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #E8C039;
		color: $black;
		border-radius: 0.25em;
		text-align: center;
		padding: 0 0.25em;
		line-height: 1.1;
		@include box-shadow(0, -1px, 0.45em, 0em, #222);
		cursor: pointer;
		span {
			font-size: 0.85em;
			font-weight: 600;
		}
		&.triggered {
			color: white;
			background-color: #de1d24;
			background-image: none;
		}
	}
}