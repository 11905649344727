@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CreateGame {
	position: relative;
	min-height: 100%;
	background-image: url('../../assets/images/background2.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	color: white;
	input:disabled {
		color: $black;
		background-color: rgb(226, 225, 225);
	}
	.CreateGame-errMsg {
		color: red;
	}
	.CreateGame-validations {
		position: absolute;
		top: 100%;
		left: 0;
		padding: 0.5em;
		margin: 0;
		font-size: 0.8em;
		color: #f00;
		text-align: center;
		background: rgba(0, 0, 0, 0.4);
		width: 100%;
		z-index: 2;
	}
	.CreateGame-createdBy {
		position: absolute;
		top: 5.85em;
		right: 1em;
		font-weight: 600;
		font-style: italic;
		span {
			display: block;
			text-align: right;
		}
	}
	.CreateGame-section {
		margin: 2em 1em 1em 1em;
		border-radius: 0.25em;
		// @include flex();
		&.groups {
			background-color: rgba(#213640, 0.8);
			display: block;
		}
		.CreateGame-sectionHeader {
			position: relative;
			height: 4em;
			background-color: #213640;
			border-top-left-radius: 0.25em;
			border-top-right-radius: 0.25em;
			padding: 1em 1.11em;
			.CreateGame-numberOfGroups {
				@include flex(flex-start, center);
						span {
					font-weight: bold;
				}
				.CreateGame-setNumberOfGroups {
					margin-left: 1em;
					background-color: $grey-offset;
					border-radius: 0.25em;
					@include flex(space-between, center);
					span {
						&.CreateGame-numberOfGroups--remove,
						&.CreateGame-numberOfGroups--add {
							margin: 0 0.2em;
							cursor: pointer;
							@include no-select();
							font-size: 1.8em;
							line-height: 1.1em;
							font-family: monospace;
						}
					}
					input {
						position: relative;
						vertical-align: middle;
						width: 2.3em;
						text-align: center;
						height: 2em;
						border-style: none;
					}

					input::-webkit-outer-spin-button,
					input::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}

					input[type='number'] {
						appearance: textfield;
						-webkit-appearance: textfield;
						-moz-appearance: textfield;
					}
				}
			}

			.CreateGame-printGroups {
				position: absolute;
				right: 0;
				top: 0;
				height: 4em;
				.CreateGame-printGroupsWarning {
					margin-right: 5em;
					height: 100%;
					color: $red;
					@include flex('right', 'center');
				}

				.CreateGame-printGroupsBtn {
					position: absolute;
					right: 0;
					top: 0;
					height: 100%;
					width: 4em;
					background-image: url('../../assets/images/icon-print.svg');
					background-size: 2.25em auto;
					background-position: center center;
					background-repeat: no-repeat;
					cursor: pointer;
				}
			}
		}
		.CreateGame-sectionBody {
			padding: 1.11em;
			white-space: nowrap;
			overflow: auto;
			@include custom-scrollbar(0.5em, $black, $white-seeThrough);
		}
		.CreateGame-subsection {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			width: 12.5em;
			background-color: #213640;
			border-radius: 0.25em;
			margin-right: 0.75em;
			white-space: normal;
			flex-grow: 1;
			&.company {
				width: 25.75em;
				max-width: 25.75em;
			}

			&.language {
				width: 12.5em;
				max-width: 12.5em;
			}
			&.button {
				margin-right: 0;
				width: 8.4em;
				background-color: transparent;
				max-width: 8.4em;
			}
			&.group {
				width: 15em;
				vertical-align: top;
				.CreateGame-subsectionBody {
					padding: 1em 0.5em;
					min-height: 10em;
				}
			}
			.CreateGame-subsectionHeader {
				position: relative;
				width: 100%;
				height: 2em;
				background-color: #226ca8;
				border-top-left-radius: 0.25em;
				border-top-right-radius: 0.25em;
				padding: 0.55em;
				@include flex('flex-start', 'center');
				.groupLabel {
					margin-right: 0.5em;
				}
				span {
					font-size: 1em;
				}
				.CreateGame-deleteGroupBtn {
					position: absolute;
					right: 0.55em;
					top: 0.55em;
					width: 1em;
					height: 1em;
					background-image: url('../../assets/images/icon-delete.svg');
					background-size: 0.75em auto;
					background-repeat: no-repeat;
					background-position: center center;
					cursor: pointer;
					z-index: 11;
					&:hover {
						background-size: 0.95em auto;
					}
					&.inactive {
						opacity: 0.5;
						cursor: not-allowed;
						&:hover {
							background-size: 0.75em auto;
						}
					}
				}
			}
			.CreateGame-subsectionBody {
				padding: 0.55em;
				.select-search-container {

					position: relative;
					width: 100%;
					margin-bottom: 1em;
					&:not(.select-search-has-focus) {
						.select-search-input {
							color: transparent;
						}
						.select-search-select {
							display: none;
						}
					}
					.select-search-input {
						font-size: 1em;
						width: 100%;
						height: 1.8em;
						line-height: 1;
						border-radius: 0.25em;
						padding: 0 0.5em;
						border-style: none;
						background-image: url('../../assets/images/icon-arrow-down-dark.svg');
						background-position: top 0.8em right 0.4em;
						background-size: auto 0.5em;
						background-repeat: no-repeat;
					}
					.select-search-select {
						position: absolute;
						width: 100%;
						color: black;
						background-color: white;
						border-bottom-left-radius: 0.25em;
						border-bottom-right-radius: 0.25em;
						box-shadow: none;
						padding: 0.25em 0;
						margin: 0;
						max-height: 5em;
						overflow: auto;
						.select-search-options {
							margin: 0;
							padding: 0;
							.select-search-row {
								width: 100%;
								height: 1.5em;
								font-size: 1em;
								line-height: 1.5;
								
								button {
									width: 100%;
									height: 1.5em;
									font-size: 1em;
									@include appearance('none');
									padding: 0 0.5em;
									margin: 0;
									background: white;
									border: none;
									outline: none;
									font-family: inherit;
									text-align: left;
								}
								&:not(:first-child) {
									border-top: none;
								}
								cursor: pointer;
								&:hover button {
									background: darken(white, 5%);
								}
							}
						}
					}

					.CreateGame-noMatchingPlayers {
						width: 100%;
						height: 1.5em;
						font-size: 0.85em;
						line-height: 1.5;
						padding: 0 0.5em;
						margin: 0;
						background: white;
						text-align: center;
						color: $red;
					}
				}
				.CreateGame-player {
					width: 100%;
					overflow: hidden;
					white-space: nowrap;
					.CreateGame-playerName {
						display: inline-block;
						vertical-align: middle;
						width: 12.5em;
						height: 1.5em;
						line-height: 1.5em;
						overflow: hidden;
						&.started {
							width: 100%;
						}
					}
					.CreateGame-playerDeleteBtn {
						display: inline-block;
						vertical-align: middle;
						width: calc(100% - 13em);
						margin-left: 0.5em;
						height: 1.5em;
						background-image: url('../../assets/images/icon-delete-red.svg');
						background-position: center center;
						background-size: auto 60%;
						background-repeat: no-repeat;
						cursor: pointer;
						&:hover {
							background-size: auto 70%;
						}
					}
				}

				.CreateGame-inputWrap {
					position: relative;
					background-color: #3d5762;
					border-radius: 0.25em;
					padding: 0.55em;
					margin-bottom: 1em;
					.CreateGame-deletePlayerBtn {
						position: absolute;
						right: 0;
						top: -0.8em;
						width: 1em;
						height: 0.5em;
						border-bottom: 0.2em solid #e85e58;
						cursor: pointer;
						&.inactive {
							opacity: 0.5;
							cursor: not-allowed;
						}
						&.hidden {
							display: none;
						}
					}
				}
				input {
					width: 100%;
					height: 1.8em;
					border-radius: 0.25em;
					padding: 0 0.5em;
					border-style: none;
					&:nth-child(3) {
						margin-top: 0.5em;
					}
				}
				.CreateGame-addPlayerBtn {
					width: 100%;
					line-height: 2;
					color: white;
					background-color: #159d69;
					border: 0.1em solid white;
					padding: 0 1em;
					border-radius: 0.25em;
					margin-top: 1em;
					cursor: pointer;
					&:hover,
					&:active {
						background-color: darken(#159d69, 5%);
					}
				}
			}
		}
	}

	.CreateGame-userInfo {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.CreateGame-games {
		display: flex;
		flex-direction: row;
		.CreateGame-game {
			display: inline-block;
			background-color: $black-seeThrough;
			@include box-shadow(0, 0.2em, 0.8em, 1px, $black-seeThrough);
			margin: 1em;
			border-radius: 0.2em;
			width: 12em;
			.CreateGame-gameTitle {
				text-align: center;
				text-transform: uppercase;
				background-color: #222;
				border-radius: 0.2em 0.2em 0 0;
				padding: 0.6em;
			}
			.CreateGame-gameCode {
				padding: 0.4em 0;
				text-align: center;
				margin: 0.4em 0 1em 0;
			}
			.CreateGame-buttons {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				padding: 0 0 1.4em 0;
				.Button {
					height: 2.4em;
					width: 80%;
					margin: 0.2em 0.4em;
					padding: 0.3em;
					color: $white;
					border-top: 0.11em solid;
					border-right: 0.17em solid;
					border-left: 0.17em solid;
					border-bottom: 0.33em solid;
					border-radius: 0.2em 0.2em 0.4em 0.4em;
					&.Button--CreateGame-play {
						background-color: $green-dark;
						border-color: #02291e;
						&:hover {
							border-color: #011b14;
						}
						&:active {
							border-bottom: 0.11em solid #011b14;
							border-top: 0.2em solid #011b14;
							border-radius: 0.33em;
						}
					}
					&.Button--CreateGame-delete {
						background-color: $red;
						border-color: #380405;
						&:hover {
							border-color: #250203;
						}
						&:active {
							border-bottom: 0.11em solid #250203;
							border-top: 0.2em solid #250203;
							border-radius: 0.33em;
						}
					}
					&.Button--CreateGame-reset {
						background-color: $orange;
						border-color: #442705;
						&:hover {
							border-color: #2b1803;
						}
						&:active {
							border-bottom: 0.11em solid #2b1803;
							border-top: 0.2em solid #2b1803;
							border-radius: 0.33em;
						}
					}
				}
			}
			.CreateGame-gameData {
				padding: 0 2em;
				font-size: 0.6em;
				div {
					margin-bottom: 1em;
				}
			}
		}
	}
}
