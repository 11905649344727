/* Colors */
$black: #213640;
$black-seeThrough: rgba($black, 0.75);
$grey: #50545A;
$grey-offset: #3D5762;
$grey-offset-seeThrough: rgba($grey-offset, 0.5);
$blue-dark: #226CA8;
$blue: #0080C9;
$blue-light: #79BDE8; 
$red-dark: #DE1D24;
$red: #E23D36;
$red-light: #E85E58;
$orange: #E89739;
$orange-light: #E8C039;
$yellow: #EBD03C;
$green-dark: #288E73;
$green: #159D69;
$green-light: #30AD87;
$white: #fff;
$white-seeThrough: rgba($white, 0.25);
$mayday-yellow: #fff200;
$time-blue: #1280C9;
