@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.BestPracticeCardBack{
    width: 100%;
    z-index: 1;
    border-radius: 0.25em;
    overflow: hidden;
    box-shadow: 0em 0.1em 0.25em 0em rgba(0, 0, 0, 0.5);
    &-inner{
        position: relative;
        width: 100%;
        height: 100%;
        text-align: left;
        background-color: #334C57;
        border-radius: 0.25em;
        color: #fff;
    }
    &-header{
        width: 100%;
        height: 2.4em;
        padding: 0 0.6em;
        background-color: #288e73;
        border-top-left-radius: 0.25em;
        border-top-right-radius: 0.25em;
        @include flex(left, center);
    }
    &-title{
        line-height: 1.2;
        font-weight: bold;
        text-transform: uppercase;
    }
    &-body{
        padding: 1em;
        min-height: 17em;
        border-bottom-left-radius: 0.25em;
        border-bottom-right-radius: 0.25em;
        @include flex(space-between);
		flex-direction:column;
    }
    &-text{
        font-size: 0.95em;
    }    
    &-effects{
        width: 100%;
        padding: 0 0.75em 0 0.75em;
        font-weight: 600;
        font-size: 1em;
    }
    &-effect{
        display: flex;
        margin: 0 0 0.8em 0;
        line-height: 2em;
        font-size: 1em;
    }
    &-effectTitle{
        text-transform: capitalize;
        width: 100%;
        height: 100%;
        padding-left: 0.4em;
        font-weight: 600;
        font-style: italic;
        text-align: left;
        span{
            text-transform: capitalize;
        }
    }
    &-effectValue{
        width: 2.6em;
        height: 2em;
        color: white;
        border-radius: 2.5em;
        text-align: center;
        font-weight: 700;
        margin: 0 0 0 0.5em;
        &.risk{
            background-color: $red;
        }
        &.cost{
            background-color: $orange-light
        }
        &.time{
            background-color: $time-blue
        }
        &.green {
            background-color: $green-light;
        }
    }
}

