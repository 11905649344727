@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';

.EventCardCRM {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: left;	
	border-radius: 0.25em;
	z-index: 10;
	perspective: 8000px;
	@include rotationStyle('preserve-3d');
	.EventCardCRM-container {
		position: relative;
		width: 100%;
		height: 100%;
		border-radius: 0.25em;
		perspective: 8000px;
		@include rotationStyle('preserve-3d');
		-webkit-backface-visibility: hidden; /* Safari */
		backface-visibility: hidden;
		z-index: 1;
		&.animate {
			.EventCardCRM-front {
				transition: transform 0.8s ease-in-out 0s;
				-webkit-transform: rotateY(180deg);
				transform: rotateY(180deg);
			}
			.EventCardCRM-back {
				transition: transform 0.8s ease-in-out 0s;
				-webkit-transform: rotateY(0deg);
				transform: rotateY(0deg);
			}
		}
		// &.back {
		// 	.EventCardCRM-front {transform: rotateY(180deg);}
		// 	.EventCardCRM-back {transform: rotateY(0deg);}
		// }
		.EventCardCRM-front,
		.EventCardCRM-back {
			position: absolute;
			width: 100%;
			height: 100%;
			-webkit-backface-visibility: hidden; /* Safari */
			backface-visibility: hidden;
			z-index: 1;
			border-radius: 0.25em;
			overflow: hidden;
			@include rotationStyle('preserve-3d');
			@include box-shadow(0em, 0.1em, 0.25em, 0em, rgba(0, 0, 0, 0.5));
		}
		.EventCardCRM-front {
			-webkit-transform: rotateY(0deg);
			transform: rotateY(0deg);
		}
		.EventCardCRM-back {
			-webkit-transform: rotateY(-180deg);
			transform: rotateY(-180deg);
		}
	}

	.EventCardCRM-disabledOverlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 0.25em;
		background-color: rgba(#50545A, 0.52);
		z-index: 2;
	}
}