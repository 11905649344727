@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.StatusPopup {
	position: fixed;
	background-color: rgba(255, 255, 255, 0.1);
	z-index: 20;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	@include blurBackground(2px);
	@include flex('center', 'center');
	&.no-status {
		.StatusPopup-content {
			width: 31em;
			height: 16.5em;
			max-height: auto;
			font-size: 1em;
			border-radius: 0.3em;
			.StatusPopup-header { 
				background-color: #288E73;
				border-radius: 0.3em 0.3em 0 0;
				height: 3.55em;
				.StatusPopup-title {
					font-size: 1.2em;
				}
				.StatusPopup-closeBtn {
					width: 3.55em;
					height: 3.55em;
					background-size: 1.25em auto;
					&:hover {
						background-size: 1.5em auto;
					}
				}
			}
			.StatusPopup-body {
				background-color: rgba(#213640, 0.85);
				border-radius: 0 0 0.3em 0.3em;
				padding: 1.83em 2.33em;
				color: white;
			}
		}
	}
	.StatusPopup-content {
		position: relative;
		width: 78em;
		height: 56em;
		max-height: 90vh;
		font-size: 0.6em;
		overflow: hidden;
		border-radius: 0.42em;
		.StatusPopup-header {
			position: relative;
			width: 100%;
			height: 4em;
			border-radius: 0.42em 0.42em 0 0;
			background-color: #3D5762;
			.StatusPopup-fullscreenBtn {
				position: absolute;
				top: 0;
				left: 0;
				width: 4em;
				height: 4em;
				background-image: url('../../assets/images/icon-fullscreen-expand.svg');
				background-size: 1.5em auto;
				background-position: center center;
				background-repeat: no-repeat;
				cursor: pointer;
				&:hover {
					background-size: 1.75em;
				}
				&.fullscreen {
					background-image: url('../../assets/images/icon-fullscreen-contract.svg');
				}
			}
			.StatusPopup-closeBtn {
				position: absolute;
				top: 0;
				right: 0;
				width: 4em;
				height: 4em;
				background-image: url('../../assets/images/icon-close.svg');
				background-size: 1.5em auto;
				background-position: center center;
				background-repeat: no-repeat;
				cursor: pointer;
				&:hover {
					background-size: 1.75em auto;
				}
			}
			.StatusPopup-title {
				text-align: center;
				color: white;
				font-size: 1.33em;
				line-height: 3;
				font-weight: bold;
			}
		}
		.StatusPopup-body {
			position: relative;
			width: 100%;
			height: calc(100% - 4em);
			overflow: hidden auto;
			@include custom-scrollbar(0.75em, $black, $white-seeThrough);

			.ReflectionResult,
			.GameResult,
			.DecisionMakingResult,
			.CommunicationResult,
			.ExerciseResult {
				padding: 2em 2em 2em 2em;
				.Logo,
				.ProgressBar,
				> .Button {
					display: none;
				}
			}
		}
	}
	&.fullscreen .StatusPopup-content {
		width: 100%;
		height: 100%;
		max-height: 100vh;
		overflow: auto;
		font-size: 1em;
	}
}