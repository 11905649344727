@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.DirtyDozenPopup-popup {
	position: fixed;
	background-color: rgba(255, 255, 255, 0.1);
	z-index: 20;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	@include blurBackground(2px);
	@include flex('center', 'center');
	.DirtyDozenPopup-content {
		position: relative;
		width: 50em;
		height: 26.5em;
		background: rgba($black, 0.84);
		border-radius: 0.25em;
		background-color: #213640;
		z-index: 20;
		.DirtyDozenPopup-closeBtn {
			position: absolute;
			top: 0;
			right: 0;
			width: 3.65em;
			height: 3.65em;
			background-image: url('../../assets/images/icon-close.svg');
			background-size: 1.25em auto;
			background-position: center center;
			background-repeat: no-repeat;
			cursor: pointer;
		}
		.DirtyDozenPopup-title {
			height: 2.56em;
			background-color: $orange-light;
			color: $black;
			border-radius: 0.2em 0.2em 0 0;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: 700;
			font-style: italic;
			font-size: 1.389em;
		}
		.DirtyDozenPopup-body {
			margin: 3.25em 1.11em;
			height: 20em;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			.DirtyDozen {
				width: 16.5em;
				height: 3.25em;
			}
		}
	}
}