@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.ReflectionResult {
	position: relative;
	min-height: 100%;
	background-image: url('../../assets/images/background2.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	padding: 7.25em 2em 2em 2em;
	color: white;
	.InfoBox {
		position: fixed;
		width: 50vw;
		height: 50vh;
		left: calc(50vw - 25%);
		top: calc(50vh - 25%);
	}

	.ReflectionResult-groups {
		display: inline-block;
		vertical-align: top;
		width: 11.25em;
		// max-height: calc(100vh - 9em);
		background-color: rgba(#213640, 0.4);
		border-radius: 0.25em 0 0 0.25em;
		.ReflectionResult-header {
			width: 100%;
			height: 2.68em;
			background-color: $green-dark;
			border-radius: 0.25em 0 0 0;
			cursor: pointer;
			@include flex('center', 'center');
			span {
				font-size: 1.25em;
				font-weight: 600;
			}
		}
		.ReflectionResult-body {
			width: 100%;
			border-right: 0.2em solid $white-seeThrough;
			.ReflectionResult-group {
				position: relative;
				height: 8em;
				padding: 1em 0.45em;
				border-bottom: 0.2em solid $white-seeThrough;
				@include flex('center', 'center');
				&:last-child {border-bottom: none;}
				&:hover {
					.ReflectionResult-groupPlayers {display: block;}
				}
				.ReflectionResult-groupTitle {
					width: 100%;
					height: 100%;
					border-radius: 0.25em;
					border: 0.1em solid #213640;
					background-color: rgba(#213640, 0.4);
					@include flex('center', 'center');
					span {
						font-size: 1.25em;
						font-weight: 600;
					}
				}
				.ReflectionResult-groupPlayers {
					display: none;
					position: absolute;
					left: 12em;
					min-width: 12em;
					background-color: #213640;
					border-radius: 0.25em;
					padding: 0.5em;
					z-index: 10;
				}
			}
		}
	}

	.ReflectionResult-groupAnswers {
		display: inline-block;
		vertical-align: top;
		width: calc(100% - 11.25em);
		// max-height: calc(100vh - 9em);
		background-color: rgba(#213640, 0.4);
		border-radius: 0 0.25em 0.25em 0;	
		overflow: auto hidden;
		@include custom-scrollbar(0.5em, $black, $grey);
		.ReflectionResult-header {
			height: 2.68em;
			background-color: $green-dark;
			white-space: nowrap;
			.ReflectionResult-headerText {
				display: inline-block;
				vertical-align: top;
				width: 30em;
				height: 100%;
				text-align: center;
				background-color: $green-dark;
				cursor: pointer;
				&.scale {width:16em;}
				span {
					font-size: 1.25em;
					line-height: 2.144;
					font-weight: 600;
				}
			}
		}
		.ReflectionResult-body {
			.ReflectionResult-groupRow {
				width: 100%;
				height: 8em;
				white-space: nowrap;
				&:last-child {
					height: 7.5em;
					.ReflectionResult-groupAnswerContainer {border-bottom: none;}
				}
				.ReflectionResult-groupAnswerContainer {
					display: inline-block;
					vertical-align: top;					
					width: 30em;
					height: 100%;
					padding: 1em;
					border-bottom: 0.2em solid $white-seeThrough;
					.ReflectionResult-groupAnswer {
						width: 100%;
						height: 100%;
						background-color: rgba(#213640, 0.4);
						padding: .3em;
						border-radius: 0.25em;
						border: 0.1em solid $black;
						overflow: hidden auto;
						@include flex('flex-start', 'flex-start');
						@include custom-scrollbar(0.5em, $black, $white-seeThrough);
						span {
							font-size: 0.8em;
							font-weight: 600;
							white-space: pre-wrap;
							padding: 0.5em;
						}
					}
					.ReflectionResult-groupAnswerDirtyDozen {
						width: 24em;
						height: 1.8em;
						background-color: #1C282E;
						border: 1px solid rgba(#EBD03C, 0.75);
						color: #EBD03C;
						border-radius: 0.33em;
						padding: 0 0.75em;
						line-height: 1.8em;
						font-weight: 600;
						margin-bottom: 0.25em;
						span {font-size: 1em;}
					}
					.ReflectionResult-groupAnswerTopics {
						width: 100%;
						height: 2.4em;
						margin-bottom: 1.11em;
						background-color: $green-dark;
						line-height: 2.4em;
						padding: 0 0.75em;
						border-radius: 0.33em;
						font-weight: bold;
						text-transform: uppercase;
						margin-bottom: 0.8em;
						span {font-size: 1em;}
					}
					&.scale {
						width:16em;
						padding:0;
						padding-right: 0.3em;
						.ReflectionResult-groupAnswer{
							border:0;
							@include flex(center, center)
						}
						.ReflectionResult-option {
							display: inline-block;
							padding: 1em;
							text-align: center;
							font-weight: 600;
							box-sizing: content-box;
							background-color: #243943;
							&:nth-child(1) {
								border-style: none;
								border-radius: 0.33em 0 0 0.33em;
							}
							&:nth-last-child(1) {
								border-radius: 0 0.33em 0.33em 0;
							}
							&--included {
								color: #fff;
							}
							&--selected {
								color: $black;
								background-color: #fff;
							}
						}
					}
				}
			}
			&.bestPractices{ 
				.ReflectionResult-groupRow:last-child{height:8em}
				.ReflectionResult-groupRow .ReflectionResult-groupAnswerContainer .ReflectionResult-groupAnswer{
					margin-top: 3px;
					height: calc(100% - 2em - 3px);
				}
				.ReflectionResult-groupQuestion{
					text-transform: uppercase;
					font-weight: bold;
					width: 100%;
					height: 2em;
					background-color: $green-dark;
					border-radius: 0.25em;
					padding-left: 1em;
					@include flex(initial, center);
				}
			}
		}
	}

	// .ReflectionResult-container {
	// 	background-color: rgba(#213640, 0.4);
	// 	border-radius: 0.25em;
	// 	.ReflectionResult-header {
	// 		width: 100%;
	// 		height: 2.75em;
	// 		background-color: $green-dark;
	// 		border-radius: 0.25em 0.25em 0 0;
	// 		display: flex;
	// 		.ReflectionResult-groupText {
	// 			font-size: 1.25em;
	// 			font-weight: 600;
	// 			width: 14.5em;
	// 			@include flex('center', 'center');
	// 		}
	// 		.ReflectionResult-headerText {
	// 			cursor: pointer;
	// 			flex: 1;
	// 			font-size: 1.25em;
	// 			font-weight: 600;
	// 			@include flex('center', 'center');
	// 		}
	// 	}
	// 	.ReflectionResult-groupsWrapper {
	// 		max-height: calc(100vh - 12em);
	// 		overflow: auto;
	// 		@include custom-scrollbar(0.5em, $black, $white-seeThrough);
	// 		.ReflectionResult-groupWrapper {
	// 			width: 100%;
	// 			height: 8em;
	// 			display: flex;
	// 			border-top: 0.2em solid $white-seeThrough;
	// 			&:first-child {
	// 				border-top: none;
	// 			}
	// 			.ReflectionResult-groupContainer {
	// 				width: 12em;
	// 				height: 100%;
	// 				font-size: 1.25em;
	// 				font-weight: 600;
	// 				border-right: 0.2em solid $white-seeThrough;
	// 				@include flex('center', 'center');
	// 			}
	// 			.ReflectionResult-answerContainer {
	// 				height: 100%;
	// 				flex: 1;
	// 				font-size: 1.25em;
	// 				font-weight: 600;
	// 				@include flex('center', 'center');
	// 				&.scale {
	// 					height: calc(100% - 1.334em);
	// 					margin: 0.667em;
	// 					font-size: 1.2em;
	// 					font-weight: 600;
	// 					border-radius: 0.208em;
	// 					.ReflectionResult-option {
	// 						display: inline-block;
	// 						padding: 1em;
	// 						text-align: center;
	// 						font-weight: 600;
	// 						box-sizing: content-box;
	// 						background-color: #243943;
	// 						&:nth-child(1) {
	// 							border-style: none;
	// 							border-radius: 0.33em 0 0 0.33em;
	// 						}
	// 						&:nth-last-child(1) {
	// 							border-radius: 0 0.33em 0.33em 0;
	// 						}
	// 						&--included {
	// 							color: $blue;
	// 						}
	// 						&--selected {
	// 							color: $blue;
	// 							background-color: #fff;
	// 						}
	// 					}
	// 				}
	// 				&.text {
	// 					display: block;
	// 					height: calc(100% - 2em);
	// 					font-size: 0.8em;
	// 					padding: 1em;
	// 					margin: 1em;
	// 					background-color: rgba(#213640, 0.4);
	// 					border: 0.25em solid rgba($black, 0.5);
	// 					border-radius: 0.3125em;
	// 					overflow: auto;
	// 					@include custom-scrollbar(0.5em, $black, $white-seeThrough);
	// 				}
	// 			}
	// 		}
	// 	}
	// }
}
