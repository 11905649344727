@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.GameBoardSTDnDDragLayer {
	pointer-events: none;
	position: absolute;
	left: 0;
	top: 0;
	width: calc(6.5em - 1.1em);
	z-index: 10;
	cursor: move;
	border-radius: 0.33em;
	@include  box-shadow(0, 0, 0.5em, 0.1em, rgba(0,0,0,0.5));
}