@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.DownloadCompanyGames {
	position: absolute;
	top: 2em;
	right: 4em;
	width: 21.5em;
	background-color: rgba(#213640, 0.67);
	padding-bottom: 0.5em;
	.DownloadCompanyGames-title {
		font-weight: 600;
		text-transform: uppercase;
		text-align: center;
		color: white;
		background-color: $green;
		line-height: 2.75em;
		margin-bottom: 0.25em;
		@include no-select();
		background-image: url('../../../assets/images/icon-download.svg');
		background-size: 1.1em auto;
		background-position: right 0.75em center;
		background-repeat: no-repeat;

	}
	.DownloadCompanyGames-btn {
		position: relative;
		display: inline-block;
		font-size: 0.85em;
		font-weight: 600;
		// text-transform: uppercase;
		text-decoration: underline;
		text-align: right;
		margin: 0.25em 1em;
		outline: none;
		touch-action: manipulation;
		pointer-events: auto;
		cursor: pointer;
		@include no-select();
		@include opacity(1);
		color: white;
		background-color: transparent;
		border: none;
		white-space:nowrap;

	}
}