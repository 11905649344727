@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CrewSlots {
	position: relative;
	width: 100%;
	height: 11.75em;
	margin: 2em auto;
	@include flex("center", "center", "row");

	.CrewSlots-slot {
		position: relative;
		width: 9.25em;
		min-width: 9.25em;
		height: 100%;
		background-image: linear-gradient(rgba(black, 0.24), rgba(black, 0.07));
		border-radius: 0.33em;
		text-align: center;
		margin: 0 2em 0 0;
		padding: 0.5em;
		@include flex("center", "center");
		&:last-child {
			margin: 0;
		}

		.CrewSlots-slotTitle {
			font-size: 1.75em;
			font-style: italic;
			font-weight: 800;
			color: rgba(black, 0.4);
		}
	}

	&.st {
		height: 13.5em;
		.CrewSlots-slot {
			width: 13.5em;
			&:last-child {
				background-color: rgba(#D9D9D6, 0.3);
				background-image: none;
			}
		}
	}
	&.heli {
		.CrewSlots-slot .CrewSlots-slotTitle {
			font-size: 1.2em;
		}
	}
}



