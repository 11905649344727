@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Companies {
	background: rgba(#213640, 0.8);
	border-radius: 0 0.25em 0.25em 0;
	margin-bottom: 2em;
	.Companies-header {
		width: 100%;
		height: 3em;
		background-color: #213640;
		border-radius: 0.25em 0 0 0;
		@include flex('center', 'center');
		font-weight: bold;
	}

	.Companies-body {
		padding: 0.5em 0;
		.Companies-company {
			width: 100%;
			@include flex('center', 'center');
			cursor: pointer;
			&:hover {
				background-color: rgba(white, 0.05);
			}
		}
	}

	.Companies-companySection {
		@include flex('center', 'center');
		width: 25%;
		height: 100%;
		text-align: center;
		padding: 0.3em 0.5em;
		&.name {
			@include flex('left', 'center');
		}
	}
}