@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CrewFeedbackHeli {
	position: relative;
	min-height: 100%;
	background-image: url('../../assets/images/background-clouds-dark.jpg');
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
	padding: 9em 3.85em 2em 3.85em;
	color: white;	
	overflow: hidden;
}

.CrewFeedback-helicopterBackground {
	position: absolute;
	top: 6em;
	left: 5.67em;
	width: 26em;
	height: 31em;
	background-image: url('../../assets/images/background-helicopter.png');
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
}

.CrewFeedbackHeli-selectedWrapper {
	display: inline-block;
	vertical-align: top;
	width: 30em;
	.CrewFeedbackHeli-row {
		@include flex('center', 'center');
		flex-wrap: wrap;
		text-align: center;
		&:nth-of-type(1) .CrewFeedbackHeli-slot {
			margin: 0 0.75em;
		}
		&:nth-of-type(2) {
			margin-top: 2em;
			@include flex('space-between','center');
		}
		.CrewFeedbackHeli-slot {
			position: relative;
			display: inline-block;
			vertical-align: top;
			width: 9.25em;
			height: 10.5em;
			background-image: linear-gradient(rgba(black, 0.24), rgba(black, 0.07));
			border-radius: 0.33em;
			text-align: center;
			margin-bottom: 0.5em;
			&.shake {
				-webkit-animation: wobble 5s cubic-bezier(0.280, 0.840, 0.420, 1) 3s infinite forwards;
				animation: wobble 5s cubic-bezier(0.280, 0.840, 0.420, 1) 3s infinite forwards;

			}
			.CrewFeedbackHeli-slotTitle {
				position: absolute;
				top: 0; 
				left: 0;
				width: 100%;
				height: 100%;
				@include flex("center", "center");
				font-size: 1.2em;
				font-style: italic;
				font-weight: 800;
				color: rgba(black, 0.4);
			}
		}
	}
}

.CrewFeedbackHeli-selectedSlot {
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 20em;
	text-align: center;
	.CrewFeedbackHeli-instructions {
		position: absolute;
		top: 0;
		left: 2em;
		width: 16.25em;
		height: 23em;
		background-image: linear-gradient(rgba(black, 0.25), rgba(black, 0.07));
		border-radius: 0.25em;
		padding: 1em;
		text-align: center;
		-webkit-transition: opacity 0.5s linear;
		transition: opacity 0.5s linear;
		&.hide {opacity: 0;}
		.CrewFeedbackHeli-instructionsTitle {
			font-weight: 800;
			font-style: italic;
		}
		.CrewFeedbackHeli-instructionsText {
			font-size: 0.85em;
		}
	}
	.CrewFeedbackHeli-selectedCrewContainer {
		position: relative;
		z-index: 10;
	}
}

.CrewFeedbackHeli-stats {
	display: inline-block;
	vertical-align: top;
	width: calc(100% - 50em);
	
}