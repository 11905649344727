@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Button {
	position: relative;
	display: inline-block;
	font-weight: bold;
	text-transform: uppercase;
	text-decoration: none;
	text-align: center;
	border: 1px solid black;
	color: black;
	background-color: white;
	padding: 0.1em 0.5em;
	border-radius: 0.125em;
	outline: none;
	touch-action: manipulation;
	pointer-events: auto;
	cursor: pointer;
	@include no-select();
	@include opacity(1);
	&:hover,
	&:focus,
	&:active {
		background-color: darken(white, 5%);
	}
	&--isDisabled {
		opacity: 0.4;
		cursor: not-allowed;
		&:hover,
		&:focus,
		&:active {
			opacity: 0.4;
		}
	}
	&--isLoading {
		background-image: url('../../assets/images/icon-loading.svg');
		background-size: auto 80%;
		background-position: center right;
		background-repeat: no-repeat;
	}

	&--login {
		position: absolute;
		bottom: -1px;
		left: -1px;
		width: calc(100% + 2px);
		height: 2.43em;
		font-size: 1.6em;
		font-weight: bolder;
		font-style: italic;
		line-height: 2.4em;
		color: white;
		background-color: $green-dark;
		border: none;
		border-radius: 0;
		&:hover {background-color: darken($green-dark, 5%);}
	}
	&--logout {
		position: absolute;
		bottom: 1em;
		left: 1em;
		height: 2em;
		width: 2em;
		background-image: url('../../assets/images/icon-logout.svg');
		background-size: 90% auto;
		background-position: center center;
		background-repeat: no-repeat;
		border: none;
		background-color: transparent;
		&.Button--popup {
			position: absolute;
			top: auto;
			left: 0;
			bottom: auto;
			background-image: url('../../assets/images/icon-logout-light.svg');
		}
		&:hover {
			background-color: transparent;
			background-size: contain;
		}
	}
}

.Button.Button--createGame {
	font-size: 1.11em;
	line-height: 1.8;
	height: 1.8em;
	color: white;
	background-color: transparent;
	border-style: none;
	text-transform: none;
	padding-left: 2.5em;
	background-image: url('../../assets/images/icon-create-game.svg');
	background-position: left center;
	background-size: auto 100%;
	background-repeat: no-repeat;
}

.Button.Button--createCompany,
.Button.Button--deletePlayers {
	font-size: 0.85em;
	padding: 0.5em 1.5em;
	color: white;
	background-color: $green;
	border: 1px solid white;
	&:hover,
	&:active {
		background-color: darken($green, 5%);
	}
	&.Button--isLoading {
		background-image: url('../../assets/images/icon-loading-white.svg');
	}
}
.Button.Button--deletePlayers {
	background-color: $red;
	&:hover,
	&:active {
		background-color: darken($red, 5%);
	}
}
.Button.Button--export {
	color: black;
	background-color: $yellow;
	&:hover,
	&:active {
		background-color: darken($yellow, 5%);
	}
}

.Button.Button--sendEmail {
	font-size: 1em;
	padding: 0.5em 2.5em;
	color: white;
	background-color: #159d69;
	border: 1px solid white;
	&:hover,
	&:active {
		background-color: darken(#159d69, 5%);
	}
	&.Button--isLoading {
		background-image: url('../../assets/images/icon-loading-white.svg');
	}
}


.Button.Button--createGame2,
.Button.Button--updateGame2 {
	width: 6em;
	font-size: 1.4em;
	line-height: 3;
	color: white;
	background-color: #159d69;
	border: 0.125em solid white;
	&:hover,
	&:active {
		background-color: darken(#159d69, 5%);
	}
	&.Button--isDisabled {
		&:hover,
		&:active {
			background-color: #159d69;
		}
	}
	&.Button--isLoading {
		background-image: url('../../assets/images/icon-loading-white.svg');
	}
}
.Button.Button--updateGame2 {
	color: #213640;
	background-color: #ebd03c;
	&:hover,
	&:active {
		background-color: darken(#ebd03c, 5%);
	}
	&.Button--isDisabled {
		&:hover,
		&:active {
			background-color: #ebd03c;
		}
	}
}

.Button.Button--home {
	font-size: 1.11em;
	line-height: 1.8;
	height: 1.8em;
	color: white;
	background-color: transparent;
	border-style: none;
	text-transform: none;
	padding-left: 2.5em;
	background-image: url('../../assets/images/icon-home.svg');
	background-position: left center;
	background-size: auto 100%;
	background-repeat: no-repeat;
}

.Button.Button--delete,
.Button.Button--cancel {
	text-transform: capitalize;
	font-style: italic;
	font-weight: 900;
	padding: 0.25em 1.875em;
	border-radius: 0.25em;
	border: none;
	span {font-size: 1.2em;}
	background-color: $yellow;
	color: $black;
	&:hover,
	&:active {
		background-color: darken($yellow, 7%);
	}
}
.Button.Button--cancel {
	background-color: $blue;
	color: #fff;
	&:hover,
	&:active {
		background-color: darken($blue, 3%);
	}
}

.Button--infoBox {
	width: 7.5em;
	color: white;
	background-color: #0080c9;
	font-size: 1em;
	font-weight: 900;
	font-style: italic;
	line-height: 2;
	text-transform: capitalize;
	padding: 0.2em 0;
	border-radius: 0.2em;
	border: none;
	white-space: nowrap;
	&:hover,
	&:active {
		background-color: darken(#0080c9, 5%);
	}
	&.Button--confirm {
		background-color: $yellow;
		color: $black;
	}
	&.Button--cancel {
		background-color: $blue;
		color: #fff;
		padding: 0.2em 0;
		span {
			font-size: 1em;
		}
	}
	&.Button--isDisabled {
		width: auto;
	}
}

.Button.Button--info {
	position: absolute;
	top: 1em;
	right: 19em;
	height: 3.89em;
	width: 2.6em;
	background-color: #213640;
	border-radius: 0.25em;
	border-style: none;
	background-image: url('../../assets/images/icon-info.svg');
	background-size: 0.65em auto;
	background-position: center center;
	background-repeat: no-repeat;
	&:hover {
		background-size: 0.75em auto;
	}
	&.Button--st {
		right: auto;
		left: 55.35em;
	}
}

.Button.Button--next,
.Button.Button--paused {
	position: absolute;
	top: 1em;
	right: 2em;
	width: 16.5em;
	height: 3.89em;
	color: white;
	background-color: $blue-dark;
	padding: 1em 0;
	margin-right: 2em;
	border-style: none;
	border-radius: 0;
	border-top-left-radius: 0.25em;
	border-bottom-left-radius: 0.25em;
	text-transform: none;
	span {
		font-size: 1.89em;
		line-height: 1;
		font-style: italic;
		font-weight: 800;
		white-space: nowrap;
	}
	&::after {
		content: '';
		height: 0;
		width: 0;
		position: absolute;
		left: 16.5em;
		top: 0;
		border-left: 2em solid $blue-dark;
		border-top: 1.945em solid transparent;
		border-bottom: 1.945em solid transparent;
	}
	&.Button--waiting {
		span {
			font-size: 1.1em;
			line-height: 1.575;
		}
	}
	&.Button--isLoading {
		background-image: url('../../assets/images/icon-loading-white.svg');
	}
	&.Button--st {
		left: 59em;
	}
}

.Button.Button--next.Button--st,
.Button.Button--paused.Button--st {
	width: 14em;
	&::after {
		left: 14em;
		border-left-width: 1.25em;
	}
	&.Button--waiting span {
		font-size: 1.1em;
		line-height: 1.75;
	}
}

.Button.Button--animate {
	-webkit-animation: bounce-right 4s ease-in 3s infinite;
	animation: bounce-right 4s ease-in 3s infinite;
}
.Button.Button--paused {
	background-color: $yellow;
	&::after {
		border-left: 2em solid $yellow;
	}
	cursor: not-allowed;
}
