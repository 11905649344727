@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CrewCardBack {
	position: relative;
	width: 100%;
	height: 100%;
	display: inline-block;
	text-align: left;	
	background-color: #213640;
	border-radius: 0.33em;
	@include flex('center', 'center');
	cursor: pointer;
	&.crewFeedbackSelected {
		display: block;
		.CrewCardBack-top {
			border-top-left-radius: 0.33em;
			border-top-right-radius: 0.33em;
			height: calc(100% - 5.75em);
			background-image: none;
			background-color: white;
			color: black;
			text-align: center;
			@include flex('center', 'center');
		}
		.CrewCardBack-bottom {
			height: 5.75em;
			bottom: 0em;
			top: auto;
		}
	}
}

.CrewCardBack-top,
.CrewCardBack-topWrapper {
	width: 100%;
	height: auto;
	text-align: left;
}

.CrewCardBack-description {
	width: 100%;
	font-size: 0.75em;
	line-height: 1.25;
	padding: 1em 2em;
	p {margin: 0;}	
}

.CrewCardBack-effects {
	width: 100%;
	padding: 0 0.75em 0 0.75em;
	font-weight: 600;
	font-size: 1em;
	.CrewCardBack-dirtyDozen {
		display: flex;
		margin: 0 0 0.8em 0;
		line-height: 2em;
		font-size: 1em;
	}
	.CrewCardBack-dirtyDozenValue {
		width: 2.6em;
		height: 2em;
		color: white;
		background-color: $orange-light;
		border-radius: 2.5em;
		text-align: center;
		font-weight: 700;
		margin: 0 0 0 0.5em;
	}
	
	.CrewCardBack-dirtyDozenTitle {
		width: 100%;
		height: 100%;
		padding-left: 0.4em;
		font-weight: 600;
		font-style: italic;
		text-align: left;
	}
	.CrewCardBack-risk {
		display: flex;
		margin: 0 0 0.8em 0;
		line-height: 2em;
		font-size: 1em;
	}
	.CrewCardBack-riskValue {
		width: 2.6em;
		height: 2em;
		color: white;
		background-color: $red;
		border-radius: 2.5em;
		text-align: center;
		font-weight: 700;
		margin: 0 0 0 0.5em;
	}
	
	.CrewCardBack-riskTitle {
		text-transform: capitalize;
		width: 100%;
		height: 100%;
		padding-left: 0.4em;
		font-weight: 600;
		font-style: italic;
		text-align: left;
	}
}

.CrewCardBack-bottom {
	position: absolute;
	top: 5.75em;
	bottom: 0em;
	left: 0;
	width: 100%;
	background-image: linear-gradient(#3D5762, #213640);
	border-bottom-left-radius: 0.33em;
	border-bottom-right-radius: 0.33em;
	&.cockpit, 
	&.cabin {
		background-size: 3.75em auto, auto;
		background-position: top 1.25em right 1.25em, center center;
		background-repeat: no-repeat;
	
	}
	&.cockpit {background-image: url('../../assets/images/icon-crew-cockpit.svg'), linear-gradient(#3D5762, #213640);}
	&.cabin {background-image: url('../../assets/images/icon-crew-cabin.svg'), linear-gradient(#3D5762, #213640);}
}

.CrewCardBack-info {
	text-align: left;
	padding: 0.8em;
}
.CrewCardBack-name,
.CrewCardBack-title,
.CrewCardBack-age {
	font-size: 0.75em;
	line-height: 2em;
	span {font-weight: 700;}
}