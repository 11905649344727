@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Gameover {
	position: relative;
	min-height: 100%;
	background-image: url('../../assets/images/background-plane.png'), url('../../assets/images/background2.jpg');
	background-size: 31em 32em, cover;
	background-position: center center;
	background-repeat: no-repeat;
	padding-bottom: 2em;
	color: white;
	// @include flex('center', 'center', 'column');
	.Logo{
		position: relative;
		// top:auto;
		// left:auto;
		margin: 0em auto 2em auto;
	}
	.Gameover-link {
		position: absolute;
		top: 1.3em;
		left: 1em;
		cursor: pointer;
		.aeroteam  {
			height: 4em;
			image-rendering:-moz-crisp-edges;
			image-rendering:-o-crisp-edges; 
			image-rendering:-webkit-optimize-contrast; 
			image-rendering:optimize-contrast;        
			-ms-interpolation-mode:nearest-neighbor; 
		}
	}

	.InfoBox-text p {
		font-size: 1.2em;
		text-align: center;
		font-style: italic;
		line-height: auto;
		margin:0;
		a{
			color:$yellow
		}
	}
}