@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.GameBoardCRM {
	position: relative;
	height: 100%;
	min-height: 38em;
	background-image: url('../../assets/images/background2.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	color: white;
	padding: 6em 1.75em 0 2.25em;
	@include custom-scrollbar(0.5em, $black, $white-seeThrough);
	&-Disabled{
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		display: block;
		z-index: 1001;
	}
	.GameBoardCRM-status {
		width: 100%;
		height: 5.56em;
		display: flex;
	}
	.GameBoardCRM-mainContent {
		position: absolute;
		left: 0;
		right: 0.80em;
		top: 12.675em;
		bottom:0;
		min-height: 25em;
		padding: 0.5em 0;
		padding-left:2.22em;
		overflow: visible auto;
		@include custom-scrollbar(0.5em, $black, $white-seeThrough);
		.GameBoardCRM-cards {
			display: inline-block;
			vertical-align: top;
			width: 57em;
			.GameBoardCRM-cardWrap {
				display: inline-block;
				vertical-align: top;
				width: 13.55em;
				height: 19.5em;
				text-align: left;
				background-image: linear-gradient(rgba(black, 0.24), rgba(black, 0.07));
				border-radius: 0.25em;
				z-index: 1;
				margin: 0 0.7em 0.7em 0;
				&:nth-child(4) .EventCardCRM,
				&:nth-child(8)  .EventCardCRM{
					margin-right: 0;
				}
				&:nth-child(5), .EventCardCRM 
				&:nth-child(6), .EventCardCRM 
				&:nth-child(7), .EventCardCRM 
				&:nth-child(8)  .EventCardCRM{
					margin-bottom: 0;
				}
				&.shake {
					cursor: pointer;
					-webkit-animation: wobble 5s cubic-bezier(0.280, 0.840, 0.420, 1) 3s infinite forwards;
					animation: wobble 5s cubic-bezier(0.280, 0.840, 0.420, 1) 3s infinite forwards;
				}
			}
		}
		.GameBoardCRM-threats {
			display: inline-block;
			vertical-align: top;
			width: 14.56em;
			min-height: 39.7em;
			margin: 0 1em 0 0;
			border-radius: 0.25em;
			background-image: linear-gradient(rgba(black, 0.24), rgba(black, 0.07));
			.GameBoardCRM-threatsTitle {
				width: 100%;
				height: 2em;
				border-radius: 0.25em 0.25em 0 0;
				display: flex;
				justify-content: center;
				align-items: center;
				font-weight: 700;
				font-style: italic;
				background-color: $black;
				color: $yellow;
				
			}
			.GameBoardCRM-threatCards {
				.GameBoardCRM-threatCard {
					height: 2.45em;
					display: flex;
					justify-content: center;
					align-items: center;
					background-image: linear-gradient($orange-light, $orange);
					color: $black;
					border-radius: 0.25em;
					text-align: center;
					line-height: 1em;
					@include box-shadow(0, -1px,0.45em, 0em, #222);
					cursor: pointer;
					&.triggered {
						-webkit-animation: triggeredThreat 1.5s linear 0s 1 forwards;
						animation: triggeredThreat 1.5s linear 0s 1 forwards;
					}
					&.animate {
						-webkit-animation: triggeredThreat 1.5s linear 0s infinite;
						animation: triggeredThreat 1.5s linear 0s infinite;
					}
				}
			}
		}
	}
}
 

.GameBoardCRM-cards{
	position: relative;
	.GameBoardCRM-cardWrap {
		.EventCardCRM{
			@include transition(all, .5s, cubic-bezier(.25,0,.28,1));
		}
		&:nth-child(1) .EventCardCRM{
			@include transition-delay(1.55s);
			@include translate(calc(-100vw));
		}
		&:nth-child(2) .EventCardCRM{
			@include transition-delay(1.40s);
			@include translate(calc(-100vw));
		}
		&:nth-child(3) .EventCardCRM{
			@include transition-delay(1.25s);
			@include translate(calc(-100vw));
		}
		&:nth-child(4) .EventCardCRM{
			@include transition-delay(1.10s);
			@include translate(calc(-100vw));
		}
		&:nth-child(5) .EventCardCRM{
			@include transition-delay(0);
			@include translate(0, calc(-100% - 0.7em));
		}
		&:nth-child(6) .EventCardCRM{
			@include transition-delay(.15s);
			@include translate(0, calc(-100% - 0.7em));
		}
		&:nth-child(7) .EventCardCRM{
			@include transition-delay(.30s);
			@include translate(0, calc(-100% - 0.7em));
		}
		&:nth-child(8) .EventCardCRM{
			@include transition-delay(.45s);
			@include translate(0, calc(-100% - 0.7em));
		}
	}
	&.cardsDealed{
		.GameBoardCRM-cardWrap {
			.EventCardCRM {
				@include translate(0, 0);
				.EventCardCRM-container {
					animation-duration: 0.5s; 
					animation-timing-function: ease;
					animation-name: bounceOut;
				}
			}
			&:nth-child(1) .EventCardCRM .EventCardCRM-container {animation-delay: 2.05s;}
			&:nth-child(2) .EventCardCRM .EventCardCRM-container {animation-delay: 1.90s;}
			&:nth-child(3) .EventCardCRM .EventCardCRM-container {animation-delay: 1.75s;}
			&:nth-child(4) .EventCardCRM .EventCardCRM-container {animation-delay: 1.60s;}
			&:nth-child(5) .EventCardCRM .EventCardCRM-container {animation-delay: .50s;}
			&:nth-child(6) .EventCardCRM .EventCardCRM-container {animation-delay: .65s;}
			&:nth-child(7) .EventCardCRM .EventCardCRM-container {animation-delay: .80s;}
			&:nth-child(8) .EventCardCRM .EventCardCRM-container {animation-delay: .95s;}
		}
	}
}