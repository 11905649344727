@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.GameBoardSTDndContainer {
	width: 100%;
	height: 100%;
	&:not(.GameBoardSTDndContainer--available) {
		padding: 0.35em 1em 0.5em 1em;
		border-radius: 0.25em;
		&.green {
			background-color: #213640;
		}
	}
	&--available {
		position: absolute;
		top: 0;
		left: 0;
	}
}