@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Company {

	.Company-saveBtn,
	.Company-deleteBtn {
		display: inline-block;
		margin-right: 1em;
		width: 10em;
		text-align: center;
		padding: 0.5em 0;
		color: white;
		background-color: $green;
		border: 1px solid white;
		opacity: 0.5;
		cursor: not-allowed;
		&.active {
			opacity: 1;
			cursor: pointer;
			&:hover,
			&:active {
				background-color: darken($green, 5%);
			}
		}
		&.saving {
			background-size: auto 80%;
			background-position: center right;
			background-repeat: no-repeat;	
			background-image: url('../../../assets/images/icon-loading-white.svg');
		}
	}

	.Company-deleteBtn {
		background-color: $red;
		&.active {
			&:hover,
			&:active {
				background-color: darken($red, 5%);
			}	
		}
	}

	.Company-title {
		font-size: 1.1em;
		text-transform: uppercase;
		font-style: italic;
		font-weight: bold;
		margin: 2em 0 0.5em 0;
	}

	.Company-companyTitle {
		font-size: 1.25em;
		margin-bottom: 1em;
		input {
			width: 25em;
			font-size: 1em;
			padding: 0.2em;
			background-color: transparent;
			border: none;
			color: white;
			&:hover,
			&:focus,
			&:active {
				background-color: rgba(white, 0.2);
				border: none;
				outline: none;
			}
		}
	}

	.Company-games {
		// @include flex('left', 'top');
		.Company-game {
			display: inline-block;
			vertical-align: top;
			width: 21em;
			margin-right: 1em;
			.Company-gameHeader {
				background-color: #213640;
				border-radius: 0.25em 0.25 0 0;
				height: 3em;
				@include flex('center', 'center');
				// .Company-gameCheckbox {
				// 	display: inline-block;
				// 	vertical-align: middle;
				// 	width: 1.5em;
				// 	height: 1.5em;
				// 	border: 1px solid white;
				// 	margin-right: 1em;
				// 	cursor: pointer;
				// 	&.checked {
				// 		background-image: url('../../../assets/images/icon-check-green.svg');
				// 		background-size: contain;
				// 		background-position: center center;
				// 		background-repeat: no-repeat;
				// 	}
				// }
				.Company-gameTitle {
					display: inline-block;
					vertical-align: middle;
					white-space: nowrap;

				}
			}
			.Company-gameScenarios {
				height: 8em;
				padding: 1em 1em;
				background: rgba(#213640, 0.8);
				.Company-gameScenario {
					display: inline-block;
					width: 50%;
					.Company-gameScenarioCheckbox {
						display: inline-block;
						vertical-align: middle;
						width: 1em;
						height: 1em;
						border: 1px solid white;
						margin-right: 0.25em;
						cursor: pointer;
						&.checked {
							background-image: url('../../../assets/images/icon-check-green.svg');
							background-size: contain;
							background-position: center center;
							background-repeat: no-repeat;
						}
					}
					.Company-gameScenarioTitle {
						display: inline-block;
						vertical-align: middle;

					}
					&.safetytraining {
						.Company-gameScenarioTitle {
							font-size: 0.8em;
						}
					}
				}
			}
		}
	}

	.Company-importPlayers {
		margin-bottom: 1em;
	}
	.Company-importPlayersErr {
		color: $red;
	}

	

}