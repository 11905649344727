@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';

.BestPracticeCardFlippable {
	position: relative;
	perspective: 2000px;
	@include rotationStyle('preserve-3d');
	-webkit-transform: translateZ(2000px);
	transform: translateZ(2000px);
    cursor: pointer;
    height:18.9em;
}

.BestPracticeCardFlippable-container {
	position: relative;
	width: 100%;
	height: 100%;
	perspective: 2000px;
	@include rotationStyle('preserve-3d');
	-webkit-backface-visibility: hidden; /* Safari */
	backface-visibility: hidden;
	z-index: 1;
	&.back {
		.BestPracticeCardFlippable-front {
			transition: transform 0.8s ease-in-out 0s;
			-webkit-transform: rotateY(-180deg);
			transform: rotateY(-180deg);
		}
		.BestPracticeCardFlippable-back {
			transition: transform 0.8s ease-in-out 0s;
			-webkit-transform: rotateY(-0deg);
			transform: rotateY(-0deg);
		}
	}
	.BestPracticeCardFlippable-front,
	.BestPracticeCardFlippable-back {
		border-radius: 0.33em;
		position: absolute;
		width: 100%;
		height: 100%;
		-webkit-backface-visibility: hidden; /* Safari */
		backface-visibility: hidden;
		z-index: 1;
		transition: transform 0.5s ease-out;
		@include rotationStyle('preserve-3d');
		@include box-shadow(0, 0.11em, 0.667em, 0em, rgba(0, 0, 0, 0.5));
	}
	.BestPracticeCardFlippable-front {
		transition: transform 0.8s ease-in-out 0s;
		-webkit-transform: rotateY(0deg);
		transform: rotateY(0deg);
	}
	.BestPracticeCardFlippable-back {
		transition: transform 0.8s ease-in-out 0s;
		-webkit-transform: rotateY(180deg);
		transform: rotateY(180deg);
	}
}