@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CrewCardBackHeli {
	position: relative;
	width: 100%;
	height: 100%;
	display: inline-block;
	text-align: left;	
	background-color: #213640;
	border-radius: 0.33em;
	@include flex('center', 'center');
	cursor: pointer;

	.CrewCardBackHeli-top {
		width: 100%;
		height: 100%;
		text-align: left;
		color: black;
		background-color: white;
	}	
	.CrewCardBackHeli-bottom {
		position: absolute;
		top: 5.75em;
		bottom: 0em;
		left: 0;
		width: 100%;
		background-image: linear-gradient(#3D5762, #213640);
		border-bottom-left-radius: 0.33em;
		border-bottom-right-radius: 0.33em;
		&.pilot, 
		&.tech,
		&.doctor {
			background-size: 3.75em auto, auto;
			background-position: top 1.25em right 1.25em, center center;
			background-repeat: no-repeat;
		
		}
		&.pilot {
			background-image: url('../../assets/images/icon-pilot-m.svg'), linear-gradient(#3D5762, #213640);
			&.f {background-image: url('../../assets/images/icon-pilot-f.svg'), linear-gradient(#3D5762, #213640);}
		}
		&.tech {background-image: url('../../assets/images/icon-tech.svg'), linear-gradient(#3D5762, #213640);}
		&.doctor {background-image: url('../../assets/images/icon-doctor.svg'), linear-gradient(#3D5762, #213640);}
	}
	
	.CrewCardBackHeli-info {
		text-align: left;
		padding: 0.8em;
		@include no-select();
	}
	.CrewCardBackHeli-name,
	.CrewCardBackHeli-title,
	.CrewCardBackHeli-age {
		font-size: 0.75em;
		line-height: 2em;
		@include no-select();
		span {font-weight: 700;}
	}
	.CrewCardBackHeli-description {
		width: 100%;
		padding: 0.75em 1.5em;
		p {
			margin: 0;
			font-size: 0.75em;
			line-height: 1.65;
		}	
	}
	.CrewCardBackHeli-experience {
		padding: 0 1.5em;
		margin-bottom: 0.5em;
		&.secondary {
			.CrewCardBackHeli-experienceScale .CrewCardBackHeli-experienceScaleNumber.selected {
				background-color: #B2B2B2;
			}
		}
		.CrewCardBackHeli-experienceLabel {
			font-size: 0.75em;
			font-weight: bold;
		}
		.CrewCardBackHeli-experienceScale {
				position: relative;
				width: 100%;
				height: 1.67em;
				margin-top: 0.25em;
				border-radius: 0.33em;
				overflow: hidden;
				padding: 0;
				@include flex('space-between', 'center');
			.CrewCardBackHeli-experienceScaleNumber {
				padding-top: 2px;
				text-align: center;
				font-weight: 600;
				color: #79BDE8;
				width: 2em;
				height: 100%;
				&.selected {
					color: white;
					background-color: #30AD87;
				}
			}
			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: calc(100% - 4px);
				height: calc(100% - 4px);
				border-radius: 0.33em;
				border: 2px solid #79BDE8;
			}
		}
	}

	.CrewCardBackHeli-issues {
		position: relative;
		padding: 0 1.5em;
		margin-top: 1em;
		.CrewCardBackHeli-issue {
			position: relative;
			padding-left: 1.5em;
			span {
				font-size: 0.85em;
				font-weight: bold;
				font-style: italic;
			}
			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: calc(50% - 0.4em);
				background-color: #E8C039;
				width: calc(0.8em + 1px);
				height: calc(0.8em + 1px);
				border-radius: 1em;

			}
		}
	}


	&.crewFeedbackSelected,
	&.crewPopup {
		display: block;
		.CrewCardBackHeli-top {
			border-top-left-radius: 0.33em;
			border-top-right-radius: 0.33em;
			height: calc(100% - 5.75em);
			background-image: none;
			// @include flex('center', 'center');
		}
		.CrewCardBackHeli-bottom {
			height: 5.75em;
			bottom: 0em;
			top: auto;
		}
	}
	&.crewPopup {
		width: 16.2em;
		height: calc(2 * 11.75em + 0.75em);
	}
}



