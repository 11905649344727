@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Admin {
	min-height: 100%;
	background-image: url('../../assets/images/background2.jpg');
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
	color: white;
}

.Admin-linkToLandingPage {
	position: relative;
	display: block;
	line-height: 1.8;
	height: 1.8em;
	font-size: 1.11em;
	font-weight: bold;
	text-transform: none;
	text-decoration: none;
	text-align: center;
	color: white;
	padding: 0.1em 0.5em;
	padding-left: 2.5em;
	border-style: none;
	outline: none;
	touch-action: manipulation;
	pointer-events: auto;
	cursor: pointer;
	@include no-select();
	@include opacity(1);
	color: white;
	background-color: transparent;
	background-image: url('../../assets/images/icon-home.svg');
	background-position: left center;
	background-size: auto 100%;
	background-repeat: no-repeat;
}

.Admin-content {
	position: relative;
	padding: 2em 4em;
	&.wide {padding: 2em 3em;}
	.Admin-loadErrMsg {
		margin: 0.5em 0;
		color: $red;
	}
}

.Admin-title {
	font-size: 1.25em;
	text-transform: uppercase;
	font-style: italic;
	font-weight: bold;
	margin-bottom: 1em;
}

