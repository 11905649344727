@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CrewCardSTFront {
	position: relative;
	width: 100%;
	height: 100%;
	display: inline-block;
	text-align: left;	
	background-color: #213640;
	border-radius: 0.33em;
	&.selected {opacity: 0.5;}
	.CrewCardSTFront-top {
		position: relative;
		height: calc(100% - 8.1em);
		padding-left: 5em;
		svg {
			position: absolute;
			left: 1em;
			bottom: 0em;
			height: 4.1em;
		}
		&.blue {svg {fill: #1380C9;}}
		&.red {svg {fill: #D93B36;}}
		&.orange {svg {fill: #E8963A;}}
		&.green {svg {fill: #189D68;}}
		&.white {svg {fill: #F4F5F6;}}
	}

	.CrewCardSTFront-bottom {
		position: absolute;
		top: calc(100% - 8.1em);
		bottom: 0;
		left: 0;
		width: 100%;		
		background-image: linear-gradient(#3D5762, #213640);
		border-bottom-left-radius: 0.33em;
		border-bottom-right-radius: 0.33em;
	}

	.CrewCardSTFront-info {
		text-align: left;
		padding: 0.8em;
	}

	.CrewCardSTFront-name,
	.CrewCardSTFront-title,
	.CrewCardSTFront-age {
		font-size: 0.75em;
		line-height: 2em;
	}
	.CrewCardSTFront-name {
		font-weight: 700;
	}
	.CrewCardSTFront-name,
	.CrewCardSTFront-title {
		text-transform: uppercase;
	}

	.CrewCardSTFront-description {
		font-size: 0.7em;
		line-height: 1.5;
		padding: 1em;
		p {margin: 0;}	
	}
}





