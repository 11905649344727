@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.GameBoardStat {
	display: inline-block;
	vertical-align: top;
	background-color: $black-seeThrough;
	border-radius: 0.25em;
	margin-right: 1em;
	width: 10.5em;
	height: 3.89em;

	&.clickable {
		cursor: pointer;
	}

	.GameBoardStat-title {
		padding: 0.75em;
		border-radius: 0.25em 0.25em 0 0;
		height: 1.9em;
		@include flex('left', 'center');
		text-align: left;
		font-weight: 700;
		font-style: italic;
		background-color: $red;
		background-size: auto 1.25em;
		background-position: right 1em center;
		background-repeat: no-repeat;
		line-height: 1;
	}
	.GameBoardStat-content {
		height: 1.99em;
		padding: 1em 0.5em;
		@include flex('center', 'center');
		.GameBoardStat-fillmeter {
			width: 100%;
			margin: 0.22em auto;		
			@include flex('space-between', 'center');
			.GameBoardStat-point {
				height: calc(0.5em + 1px);
				width: calc(0.5em + 1px);
				border-radius: calc(0.5em + 1px);
				background-color: $white;
				&.filled {
					background-color: $red;
				}
				&.animate {
					animation: glowPulse 1s linear 0s infinite;	
				}
			}

		}
	}

	&.inconvenience,
	&.risk-crm,
	&.risk-feedback,
	&.dirtydozen {
		margin-right: 1.25em;
		width: 11.11em;
		height: auto;
		.GameBoardStat-title {
			height: 2em;
			@include flex('center', 'center');
		}
		.GameBoardStat-content {
			height: 3.55em;
			padding: 1em ;
			.GameBoardStat-fillmeter {
				.GameBoardStat-point {
					height: calc(0.67em + 1px);
					width: calc(0.67em + 1px);
					border-radius: calc(0.67em + 1px);
					&.filled {@include backgroundsLightenInvert(#de1d24, 20);}
					&.big {
						height: calc(0.9em + 1px);
						width: calc(0.9em + 1px);
						border-radius: calc(0.9em + 1px);
					}
				}
			}
		}
	}
	&.risk-feedback {
		margin-left: calc(100% - (11.11em + 1.875em));
	}
	&.inconvenience {
		.GameBoardStat-title {
			background-color: $blue;
		}
		.GameBoardStat-content {
			.GameBoardStat-point.filled {
				&.filled {@include backgroundsDarken($blue-light, 10);}
			}
		}
	}
	&.dirtydozen {
		.GameBoardStat-title {
			color: $black;
			background-color: $orange-light;
		}
		.GameBoardStat-content {
			span {
				font-size: 1.385em;
				font-weight: 700;
				font-style: italic;	
			}
			&.animate span {
				animation: textGlow 1s linear 0s infinite;
			}
		}
	}

	&.time {
		margin-left: 1em;
		.GameBoardStat-title {
			background-color: $time-blue;
		}
		.GameBoardStat-content .GameBoardStat-fillmeter .GameBoardStat-point {
			&.filled {
				background-color: $time-blue;
			}
			&.animate:not(.filled) {
				border-color: $time-blue;
			}
		}
		.GameBoardStat-title {
			background-image: url('../../assets/images/icon-time.svg');
		}
	}
	&.risk {
		width: 16em;
		.GameBoardStat-title {
			background-image: url('../../assets/images/icon-risk.svg');
		}
		.GameBoardStat-content {
			padding: 1em 0.75em;
			.GameBoardStat-fillmeter .GameBoardStat-point {
				height: calc(0.83em + 1px);
				width: calc(0.83em + 1px);
				border-radius: calc(0.83em + 1px);	
			}
		}
	}

	&.cost {
		.GameBoardStat-title {
			color: #213640;
			background-color: #E8C039;
			background-image: url('../../assets/images/icon-cost.svg');
		}
		.GameBoardStat-content .GameBoardStat-fillmeter .GameBoardStat-point {
			&.filled {background-color: #E8C039;}	
			&.animate:not(.filled) {border-color: #E8C039;}
		}
	}

	&.crewSafety,
	&.missionSuccess,
	&.compliance {
		width: 13.55em;
		height: 5.56em;
		margin-right: 0.7em;
		.GameBoardStat-title {
			height: 2em;
			background-color: #0080C9;
			@include flex('center', 'center');
		}
		.GameBoardStat-content {
			height: 3.56em;
			padding: 1em;
			.GameBoardStat-fillmeter .GameBoardStat-point {
				width: calc(0.75em + 1px);
				height: calc(0.75em + 1px);
				border-radius: calc(0.75em + 1px);
				&.filled {background-color: #79BDE8;}	
			}
		}
	}
}
