@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.ProgressBar {
	position: absolute;
	top: 1em;
	left: 15em;
	right: 21.5em;
	height: 3.89em;
	.ProgressBar-backBtn {
		display: inline-block;
		vertical-align: middle;
		width: 1.25em;
		height: 100%;
		background-image: url('../../assets/images/icon-back.svg');
		background-size: auto 3.33em;
		background-position: right center;
		background-repeat: no-repeat;
		margin-right: 0.25em;
		cursor: pointer;
	}
	.ProgressBar-text {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		width: 100%;
		height: 100%;
		font-size: 1.25em;
		line-height: 1;
		padding: 1.056em 1em;
		font-style: italic;
		font-weight: 700;
		background-color: rgba(#213640, 0.4);
		border-radius: 0.2em;
		span {text-transform: uppercase;}
	}
	.ProgressBar-flightInfoBtn {
		position: absolute;
		top: 0;
		right: 0.5em;
		padding: 0.945em 1em;
		line-height: 2;
		font-style: italic;
		font-weight: 800;
		background:$grey-offset;
		cursor: pointer;
	}
	&.link {
		padding-right: 13em;
	}
	&.backLink {
		.ProgressBar-text {
			width: calc(100% - 1.25em);
		}
	}
	&.safetytraining {
		left: 1em;
		right: auto;
		width: 28em;
		cursor: pointer;
		.ProgressBar-text {
			span {text-transform: none;}
		}
		&.game-board {
			position: relative;
			display: inline-block;
			vertical-align: top;
			width: 13.5em;	
			left: auto;
			top: auto;
		}
	}
	&.crm-helicopters {
		left: calc(2.25em + 13.55em + 0.7em);
		padding-right: 17.25em;
		.ProgressBar-flightInfoBtn {
			width: 15.88em;
			text-align: center;
		}
	}
}