@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.EventCardCRMFront {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: left;
	background-image: linear-gradient(#30ad87, #288e73);
	border-radius: 0.25em;
	&.critical {
		background-image: linear-gradient(#e85e58, #e23d36);
		.EventCardCRMFront-header {
			background-color: #de1d24;
		}
	}
	&.possibleThreat {
		background-image: linear-gradient(#3d5762, #213640);
		.EventCardCRMFront-header {
			background: linear-gradient($orange-light, $orange);
			color: $black;
		}
	}
	&.required {
		.EventCardCRMFront-body .EventCardCRMFront-options .EventCardCRMFront-option {
			&.noCost.selected {
				border: 1px solid white;
			}
		}
	}
	&.crm-helicopters {
		.EventCardCRMFront-body .EventCardCRMFront-options .EventCardCRMFront-option {
			margin-bottom: 1em;
		}
	}
	.EventCardCRMFront-header {
		width: 100%;
		height: 3.55em;
		padding: 0 0.5em;
		background-color: #288e73;
		border-top-left-radius: 0.25em;
		border-top-right-radius: 0.25em;
		@include flex('left', 'center');
		.EventCardCRMFront-title {
			line-height: 1.2;
			font-weight: bold;
		}
	}

	.EventCardCRMFront-body {
		padding: 0.8em 0.6em;
		border-bottom-left-radius: 0.25em;
		border-bottom-right-radius: 0.25em;
		.EventCardCRMFront-text {
			p {
				margin: 0;
			}
			font-size: 0.75em;
		}
		.EventCardCRMFront-options {
			margin-top: 0.6em;
			.EventCardCRMFront-option {
				width: 100%;
				margin-bottom: 0.25em;
				&.selectable {
					cursor: pointer;
				}
				.EventCardCRMFront-optionCost {
					display: inline-block;
					vertical-align: top;
					margin-right: 0.6em;
					width: calc(2.2em + 0.33em);
					text-align: right;
					.EventCardCRMFront-optionFree {
						position: relative;
						line-height: 1;
						margin-top: -0.25em;
						white-space: nowrap;
						span {
							font-size: 0.85em;
							font-weight: bold;
							font-style: italic;
						}
						&:after {
							content: "";
							position: absolute;
							font-size: 1em;
							right: 0.2em;
							bottom: -1.2em;
							width: 1.1em;
							height: 1.1em;
							border: 0.2em solid white;
							box-sizing: border-box;
						}
						&.selected {
							&:after {
								background-image: url('../../assets/images/icon-check.svg');
								background-size: 90% auto;
								background-position: center center;
								background-repeat: no-repeat;
							}
						}

					}
					.EventCardCRMFront-resource {
						display: inline-block;
						vertical-align: top;
						margin-right: 0.16em;
						width: 1.1em;
						height: 1.1em;
						border: 0.2em solid white;
						&.selected {
							background-color: white;
						}
					}
				}
				.EventCardCRMFront-optionText {
					display: inline-block;
					vertical-align: top;
					width: calc(100% - (2.2em + 0.33em + 0.6em));
					padding-top: 0.1em;
					span {
						strong {
							text-transform: uppercase;
						}
						display: block;
						font-size: 0.75em;
						line-height: 1.2;
					}
				}
				&.wide {
					.EventCardCRMFront-optionCost {
						width: calc(3.3em + 0.5em);
					}
					.EventCardCRMFront-optionText {
						width: calc(100% - (3.3em + 0.5em + 0.6em));
					}
				}
				&.default {
					margin-top: 0.8em;
					margin-bottom: 0;
					.EventCardCRMFront-optionCost {
						display: none;
					}
					.EventCardCRMFront-optionText {
						width: 100%;
						span strong {text-transform: none;}
					}
				}
			}
		}
	}
	.EventCardCRMFront-moveOverlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 0.25em;
		background-color: rgba(black, 0.5);
		background-size: 25% auto;
		background-repeat: no-repeat;
		background-position: center center;
	}
	.EventCardCRMFront-moveMessage {
		@include flex('space-around', 'center');
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 0.8em 1.2em;
		p {
			margin: 0;
			padding: 0;
			font-weight: bold;
			font-style: italic;
			font-size: 1em;
		}
		svg {
			height: 0.8em;
			width: auto;
		}
	}
}
