@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.InfoBox {
	position: relative;

	.InfoBox-box {
		position: relative;
		margin: auto;
		max-width: 48em;
		background-color: #213640;
		border-radius: 0.3em;
		.InfoBox-header {
			position: relative;
			height: 3.65em;
			background-color: $green;
			border-top-left-radius: 0.3em;
			border-top-right-radius: 0.3em;
			padding: 1.05em 0;
			.InfoBox-title {
				font-size: 1.55em;
				line-height: 1;
				color: white;
				font-style: italic;
				font-weight: 800;
				text-align: center;
			}
			.InfoBox-closeBtn {
				position: absolute;
				top: 0;
				right: 0;
				width: 3.65em;
				height: 3.65em;
				background-image: url('../../assets/images/icon-close.svg');
				background-size: 1.25em auto;
				background-position: center center;
				background-repeat: no-repeat;
				cursor: pointer;
			}
		}
		.InfoBox-body {
			padding: 3em 6em;
			text-align: left;
			@include flex(space-between, center);
			flex-direction:column;
			.InfoBox-text {
				color: white;
				p:first-of-type {
					margin-top: 0;
				}
			}
			.InfoBox-buttons {
				position: relative;
				min-width: 100%;
				margin-top: 1em;
				text-align: center;
				// min-height: 7em;
				@include flex(space-evenly, center);
			}
		}
	}

	&.fullscreen {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(white, 0.12);
		backdrop-filter: blur(4px);
		@include flex('center', 'center');
		z-index: 1000;
	}

	&.briefing {
		.InfoBox-box {
			width: 75%;
			.InfoBox-header {background-color: $green-dark;}
			.InfoBox-body {
				width: 100%;
				bottom: 0;
				overflow: auto;
				padding: 1.5em 3em;
				max-height: calc(100vh - 7em);
				@include custom-scrollbar(0.5em, $black, $grey);
				.InfoBox-text {
					.InfoBox-textTitle {
						font-size: 1.55em;
						font-weight: 900;
						font-style: italic;
						text-align: center;
						margin-bottom: 1em;
					}
					.InfoBox-textSection {
						margin-bottom: 2em;
					}
					.InfoBox-textSectionRow {
						span {
							display: inline-block;
							vertical-align: top;
							&:nth-child(odd) {
								min-width: 8em;
							}
							&:nth-child(even) {
								width: calc(100% - 8em);
							}
						}
					}
				}
			}
		}
	}

	&.gameover {
		.InfoBox-box {
			height: auto;
			width: 48em;
			background-color: rgba(#213640, 0.7);
			.InfoBox-body {
				padding: 1.5em 2em;
			}
		}
	}
}
