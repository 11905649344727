@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';

.CrewDndCard {
	position: relative;
	width: 100%;
	height: 11.75em;
	background-color: hsl(199, 32%, 19%);
	border-radius: 0.33em;
	cursor: pointer;

	&.crewSelectPopup {
		cursor: default;
		.CrewDndCard-description {
			opacity: 1;
			animation: none;
		}
	}
	
	.CrewDndCard-top {
		height: 6em;
		background-size: 3.75em auto;
		background-position: top 1.25em center;
		background-repeat: no-repeat;
		&--cockpit {background-image: url('../../assets/images/icon-crew-cockpit.svg');}
		&--cabin {background-image: url('../../assets/images/icon-crew-cabin.svg');}
		&--pilot {
			background-image: url('../../assets/images/icon-pilot-m.svg');
			&.f {background-image: url('../../assets/images/icon-pilot-f.svg');}
		}
		&--tech {background-image: url('../../assets/images/icon-tech.svg');}
		&--doctor {background-image: url('../../assets/images/icon-doctor.svg');}
	}
	
	.CrewDndCard-bottom {
		position: absolute;
		top: 5.75em;
		bottom: 0;
		left: 0;
		width: 100%;		
		background-image: linear-gradient(#3D5762, #213640);
		border-bottom-left-radius: 0.33em;
		border-bottom-right-radius: 0.33em;
	}

	.CrewDndCard-info {
		text-align: left;
		padding: 0.8em;
		&.ja {
			font-size: 0.95em;
		}
	}
	.CrewDndCard-name,
	.CrewDndCard-title,
	.CrewDndCard-age {
		white-space: nowrap;
		font-size: 0.75em;
		line-height: 2em;
		span {
			font-weight: 700;
		}
	}

	.CrewDndCard-description {
		font-size: 0.75em;
		line-height: 1.65;
		padding: 1em;
		opacity: 0;
		animation: fadeIn 0.15s linear 0.25s forwards;
		p {margin: 0;}
	}

	&--draggable {cursor: move;}
	&--focus {
		height: 18.75em;
		.CrewDndCard-top {
			height: 7em;
			background-position: top 1.25em right 1.25em;
			&--tech,
			&--doctor {
				background-position: top 1.5em right 1.25em;
			}

		}
		.CrewDndCard-description {padding: 1.6em;}
		.CrewDndCard-info {padding: 1em 1.2em 1.2em 1.2em;}
		.CrewDndCard-bottom {top: 6.2em;}
	}
}




.CrewDndCard.st {
	.CrewDndCard-top {
		height: 5.75em;
		background-size: auto 5.11em;
		background-position: center bottom 0;
		background-repeat: no-repeat;
		background-image: url('../../assets/images/icon-staff.svg');
		&--apprentice {
			background-image: url('../../assets/images/icon-staff-apprentice.svg');
		}
	}	

	.CrewDndCard-name,
	.CrewDndCard-title {
		text-transform: uppercase;
	}
	.CrewDndCard-title,
	.CrewDndCard-age {
		font-weight: normal;
		span {font-weight: normal;}
	}

	&.CrewDndCard--focus {
		height: 16.25em;
		.CrewDndCard-top {
			height: 6.35em;
			background-size: auto 5.55em;
			background-position: left 1em bottom 0;
			padding-left: 7em;
		}
		.CrewDndCard-info {
			padding: 0.8em;
		}
		.CrewDndCard-bottom {
			top: 6.35em;
		}
	}

	&.placed {
		width: 13.5em;
		height: 13.5em;
		text-align: left;
		.CrewDndCard-top {
			position: relative;
			height: calc(100% - 8.1em);
			background-image: none;
			padding-left: 5em;
			svg {
				position: absolute;
				left: 1em;
				bottom: 0em;
				height: 4.1em;
			}
			&.blue {svg {fill: #1380C9;}}
			&.red {svg {fill: #D93B36;}}
			&.orange {svg {fill: #E8963A;}}
			&.green {svg {fill: #189D68;}}
			&.white {svg {fill: #F4F5F6;}}
		}
		.CrewDndCard-description {font-size: 0.7em;}
		.CrewDndCard-info {padding: 0.8em;}
		.CrewDndCard-bottom {top: calc(100% - 8.1em);}
	}
}