@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.FacilitatorGames {
	background: rgba(#213640, 0.8);
	border-radius: 0 0.25em 0.25em 0;
	margin-bottom: 1em;
	.FacilitatorGames-header {
		width: 100%;
		height: 3em;
		background-color: #213640;
		border-radius: 0.25em 0 0 0;
		@include flex('center', 'center');
		font-weight: bold;
	}

	.FacilitatorGames-body {
		padding: 0.5em 0;
		.FacilitatorGames-game {
			width: 100%;
			// cursor: pointer;
			// &:hover {background-color: rgba(white, 0.05);}
			@include flex('center', 'center');
		}
	}

	.FacilitatorGames-section {
		@include flex('left', 'center');
		width: 20%;
		height: 100%;
		text-align: center;
		padding: 0.3em 0.5em;
		white-space: nowrap;
		overflow: hidden;
		&.date {width: 14%};
		&.type {width: 32%;}
		&.scenario {width: 18%;}
		&.phase {width: 26%;}
		&.groups,
		&.players {
			width: 10%;
			@include flex('center', 'center');
		}
	}
}