@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.ReflectionScale {
	width: 100%;
	height: 100%;
	@include flex('center', 'center');
}

.ReflectionScale-content {
	position: relative;
	width: 35em;
	height: 4.75em;
	border-radius: 0.33em;
}

.ReflectionScale-header {
	width: 100%;
	height: 2em;
	padding: 0.5em 1em;
	background-color: $blue;
	border-top-left-radius: 0.33em;
	border-top-right-radius: 0.33em;
}

.ReflectionScale-leftText,
.ReflectionScale-rightText {
	line-height: 1;
	font-style: italic;
	font-weight: 800;
}
.ReflectionScale-leftText {float: left;}
.ReflectionScale-rightText {float: right;}

.ReflectionScale-body {
	width: 100%;
	height: 2.75em;
	background-color: rgba(#213640, 0.85);
	border-bottom-left-radius: 0.33em;
	border-bottom-right-radius: 0.33em;
}

.ReflectionScale-option {
	display: inline-block;
	width: calc((100% - 1em) / 5);
	line-height: 2.75em;
	text-align: center;
	font-weight: 600;
	border-left: 0.25em solid rgba($blue, 0.4);
	box-sizing: content-box;
	cursor: pointer;
	&:nth-child(1) {
		border-style: none;
		border-bottom-left-radius: 0.33em;
	}
	&:nth-last-child(1) {
		border-bottom-right-radius: 0.33em;
	}
	&--included {
		color: $blue;
	}
	&--selected {
		color: $blue;
		background-color: white;
		border-color: white;
	}
}