@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.ReflectionDnDItem {
	width: 24em;
	height: 100%;
	background-color: #1C282E;
	border: 1px solid rgba(#EBD03C, 0.75);
	color: #EBD03C;
	border-radius: 0.33em;
	padding: 0 0.75em;
	line-height: 2.25em;
	font-weight: 600;
	cursor: move;
	span {
		font-size: 1.11em;
	}
}
