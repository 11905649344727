@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.DeleteGamePopup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(white, 0.12);
	backdrop-filter: blur(4px);
	@include flex('center', 'center');

	.DeleteGamePopup-content {
		position: relative;
		width: 30em;
		min-height: 14em;
		padding: 2em 2em 1em 2em;
		color: white;
		background-color: #213640;
		border-radius: 0.3em;
		@include flex(space-evenly, normal, column);
	}

	.DeleteGamePopup-closeBtn {
		position: absolute;
		top: 0;
		right: 0;
		width: 2.5em;
		height: 2.5em;
		background-image: url('../../assets/images/icon-close.svg');
		background-size: 1em auto;
		background-position: center center;
		background-repeat: no-repeat;
		cursor: pointer;
	}

	.DeleteGamePopup-title {
		font-size: 1.5em;
		text-align: center;
		font-weight: bold;
		margin-bottom: 2em;
		background: $green;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		padding: 0.5em 1em;
		font-style: italic;
		border-radius: 0.2em 0.2em 0 0;
	}
	.DeleteGamePopup-buttons {
		@include flex(space-evenly);
	}
}