@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';

.CrewCardFlippable {
	position: relative;
	width: 16.25em;
	height: 16em;
	perspective: 1000px;
	@include rotationStyle('preserve-3d');
	-webkit-transform: translateZ(1000px);
	transform: translateZ(1000px);
	cursor: pointer;
	&.crewPopupSmall {
		width: 9.25em;
		height: 11.75em;
	}
	&.crewFeedback,
	&.crewFeedbackWhite {
		width: 9.25em;
		height: 10.5em;
	}
	&.crewFeedbackSelected {
		width: 18.25em; // 16.25em crm
		height: 23em;
		margin: auto;
	}
	&.memberRemoved{
		@include opacity(0.3);
	}
}

.CrewCardFlippable-container {
	position: relative;
	width: 100%;
	height: 100%;
	perspective: 1000px;
	@include rotationStyle('preserve-3d');
	-webkit-backface-visibility: hidden; /* Safari */
	backface-visibility: hidden;
	z-index: 1;
	&.back {
		.CrewCardFlippable-front {transform: rotateY(180deg);}
		.CrewCardFlippable-back {transform: rotateY(0deg);}
	}
	.CrewCardFlippable-front,
	.CrewCardFlippable-back {
		border-radius: 0.33em;
		position: absolute;
		width: 100%;
		height: 100%;
		-webkit-backface-visibility: hidden; /* Safari */
		backface-visibility: hidden;
		z-index: 1;
		transition: transform 0.5s ease-out;
		@include rotationStyle('preserve-3d');
		@include box-shadow(0, 0.11em, 0.667em, 0em, rgba(0, 0, 0, 0.5));
	}
	&.noAnimation {
		.CrewCardFlippable-front,
		.CrewCardFlippable-back {
			transition-duration: 0s;
		}
	}
	.CrewCardFlippable-front {
		-webkit-transform: rotateY(0deg);
		transform: rotateY(0deg);
	}
	.CrewCardFlippable-back {
		-webkit-transform: rotateY(-180deg);
		transform: rotateY(-180deg);
	}
}