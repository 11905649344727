@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.GroupPopup {
	position: fixed;
	background-color: rgba(255, 255, 255, 0.1);
	z-index: 20;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	@include blurBackground(2px);
	@include flex('center', 'center');
	.GroupPopup-content {
		position: relative;
		width: 78em;
		height: 56em;
		max-height: 90vh;
		font-size: 0.6em;
		overflow: hidden;
		border-radius: 0.42em;
		.GroupPopup-header {
			position: relative;
			width: 100%;
			height: 4em;
			border-radius: 0.42em 0.42em 0 0;
			background-color: #3D5762;
			.GroupPopup-fullscreenBtn {
				position: absolute;
				top: 0;
				left: 0;
				width: 4em;
				height: 4em;
				background-image: url('../../assets/images/icon-fullscreen-expand.svg');
				background-size: 1.5em auto;
				background-position: center center;
				background-repeat: no-repeat;
				cursor: pointer;
				&:hover {
					background-size: 1.75em;
				}
				&.fullscreen {
					background-image: url('../../assets/images/icon-fullscreen-contract.svg');
				}
			}
			.GroupPopup-closeBtn {
				position: absolute;
				top: 0;
				right: 0;
				width: 4em;
				height: 4em;
				background-image: url('../../assets/images/icon-close.svg');
				background-size: 1.5em auto;
				background-position: center center;
				background-repeat: no-repeat;
				cursor: pointer;
				&:hover {
					background-size: 1.75em auto;
				}
			}
			.GroupPopup-title {
				text-align: center;
				color: white;
				font-size: 1.33em;
				line-height: 3;
				font-weight: bold;
			}
		}
		.GroupPopup-page {
			position: relative;
			width: 100%;
			height: calc(100% - 4em);
			overflow: hidden auto;
			@include custom-scrollbar(0.75em, $black, $white-seeThrough);
			.InfoBox.fullscreen {
				position: absolute !important;
			}
			.InfoMapPopup {
				position: absolute !important;
				.InfoMapPopup-content {
					width: calc(100% - 2em);
					height: calc(100% - 2em);
				}
			}
		}
	}
	&.fullscreen .GroupPopup-content {
		width: 100%;
		height: 100%;
		max-height: 100vh;
		overflow: auto;
		font-size: 1em;
	}

}