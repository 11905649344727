@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CrewCardSTBack {
	position: relative;
	width: 100%;
	height: 100%;
	display: inline-block;
	text-align: left;	
	background-color: #213640;
	border-radius: 0.33em;
	&.clickable {cursor: pointer;}
	&.selected {opacity: 0.5;}
	.CrewCardSTBack-top {
		height: 5.75em;
		
		.CrewCardSTBack-imageAndTitle {
			width: 6.5em;
			height: 100%;
			padding: 0.55em 0.55em 0 0.55em;
			display: inline-block;
			vertical-align: top;
			text-align: center;
			.shake{
				-webkit-animation: wobble 5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s infinite forwards;
				animation: wobble 5s cubic-bezier(0.280, 0.840, 0.420, 1) 0s infinite forwards;
			}
			.CrewCardSTBack-image {
				display: inline-block;
				width: 100%;
				height: 3.7em;
				padding-top: 0.25em;
				border: 2px solid black;
				border-radius: 0.33em;
				svg {
					width: 100%;
					height: 100%;
				}
				@include no-select();
			}
			.CrewCardSTBack-title {
				font-size: 0.6em;
				font-weight: 600;
				text-transform: uppercase;
				margin-top: 0.25em;
				white-space: nowrap;
				@include no-select();
			}
		}
		.CrewCardSTBack-info {
			width: calc(100% - 6.5em);
			padding: 0.55em 0.55em 0.55em 0;
			display: inline-block;
			vertical-align: top;
			text-align: left;
			.CrewCardSTBack-nameAndAge {
				font-size: 0.75em;
				font-weight: 700;
				text-transform: uppercase;
				@include no-select();
			}
			.CrewCardSTBack-skills {
				width: 100%;
				margin-top: 0.5em;
				.CrewCardSTBack-skill {
					width: 100%;
					text-align: center;
					color: white;
					background-color: #288E73;
					border-radius: 0.33em;
					line-height: 1.3333em;
					margin-bottom: 0.55em;
					@include no-select();
					span {
						font-size: 0.667em;
						font-weight: 600;
		
					}
				}
			}
		}
		&.blue {
			.CrewCardSTBack-imageAndTitle .CrewCardSTBack-image {
				border-color: #1380C9;
				svg {fill: #1380C9;}
			}
		}
		&.red {
			.CrewCardSTBack-imageAndTitle .CrewCardSTBack-image {
				border-color: #D93B36;
				svg {fill: #D93B36;}
			}
		}
		&.orange {
			.CrewCardSTBack-imageAndTitle .CrewCardSTBack-image {
				border-color: #E8963A;
				svg {fill: #E8963A;}
			}
		}
		&.green {
			.CrewCardSTBack-imageAndTitle .CrewCardSTBack-image {
				border-color: #189D68;
				svg {fill: #189D68;}
			}
		}
		&.white {
			.CrewCardSTBack-imageAndTitle .CrewCardSTBack-image {
				border-color: #F4F5F6;
				svg {fill: #F4F5F6;}
			}
		}

	}
	.CrewCardSTBack-bottom {
		position: absolute;
		width: 100%;
		top: 5.75em;
		bottom: 0em;
		left: 0;
		background-image: linear-gradient(#3D5762, #213640);
		padding: 0.3em;
		border-bottom-left-radius: 0.3em;
		border-bottom-right-radius: 0.33em;
		.CrewCardSTBack-dirtyDozen {
			display: inline-block;
			vertical-align: top;
			width: calc(50% - 0.5em);
			height: 2em;
			margin: 0.25em;
			background-color: rgba(#1C282E, 0.5);
			border-radius: 0.33em;
			@include no-select();
			&.active {
				background-color: #1C282E;
				border: 1px solid rgba(#EBD03C, 0.75);
				color: #EBD03C;
			}
			&.multi {
				border: 1px solid #DE1D24;
			}
			&.animate {
				animation: glowPulse 1s linear 0s infinite;
			}
			span {
				display: block;
				width: 100%;
				height: 100%;
				@include flex('center', 'center');
				font-size: 0.667em;
				font-weight: 600;
				text-align: center;
				line-height: 1.15;
				padding: 0.25em;
			}
		}
	}
	
}