@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.AdminLogin {
	min-height: 100%;
	background-image: 
		url('../../assets/images/background-plane.png'),
		url('../../assets/images/background.jpg');
	background-size: 32em auto, cover;
	background-position: center, center;
	background-repeat: no-repeat;
	text-align: center;
	@include flex('center', 'center');
	.AdminLogin-container {
		display: inline-block;
		text-align: left;
		min-height: 16.5em;
		.AdminLogin-gameTitle {
			color: #FFF200;
			text-align: center;
			font-weight: 600;
			margin-bottom: 2em;
		}
		.AdminLogin-formContainer {
			width: 22.25em;
			text-align: left;
			background-color: $black-seeThrough;
			border: 0.35em solid $green-dark;
			border-radius: 0 0 0.25em 0.25em;
			.AdminLogin-loadingWrapper {
				height: 12.25em;
				background-image: url('../../assets/images/icon-loading.svg');
				background-size: auto 10em;
				background-position: center center;
				background-repeat: no-repeat;
			}
			.AdminLogin-form {
				position: relative;
				height: 13.5em;
				padding: 1.2em;
				input {
					width: 13.85em;
					height: 1em;
					border: none;
					border-radius: 0.35em;
					display: block;
					padding: 1em 1em 1em 0.5em;
					margin: auto;
					margin-bottom: 1.2em;
					background-color: $black;
					color: $white;
					&::placeholder {color: rgba(255, 255, 255, 0.5)}
				}
				.AdminLogin-errorMessage {
					height: 1.5em;
					margin: 0 1em 0.6em 1em;
					font-size: 0.85em;
					color: $red-light;
					text-align: center;
				
				}
				.AdminLogin-button {
					position: absolute;
					bottom: -1px;
					left: -1px;
					width: calc(100% + 2px);
					height: 2.43em;
					background-color: $green-dark;
					border: none;
					color: white;
					font-size: 1.6em;
					font-weight: bolder;
					font-style: italic;
					text-align: center;
					line-height: 2.4em;
					font-family: inherit;
					text-transform: uppercase;
					cursor: pointer;
					&.loading {
						background-image: url('../../assets/images/icon-loading-white.svg');
						background-size: auto 80%;
						background-position: center right;
						background-repeat: no-repeat;
					}
					&:hover {
						background-color: darken($green-dark, 5%);
					}
					&:disabled {
						color: rgba(255, 255, 255, 0.5);
						cursor: not-allowed;
					}
				}	
			}
		}
	}
}