@-webkit-keyframes fadeIn {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}
@keyframes fadeIn {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}

@-webkit-keyframes bounceOut { 
	0% {@include scale(1);} 
	70% {@include scale(1.05);} 
	85% {@include scale(1);} 
	100% {@include scale(1);} 
}  
@keyframes bounceOut { 
	0% {@include scale(1);} 
	70% {@include scale(1.05);} 
	85% {@include scale(1);} 
	100% {@include scale(1);} 
}  

@-webkit-keyframes bounce-right {
  0% {
		@include translate(0.5em, 0);
		@include animationTimingFunction('ease-in');
  }
  8% {
		@include translate(0.27em, 0);
		@include animationTimingFunction('ease-in');  }
  13% {
		@include translate(0.135em, 0);
		@include animationTimingFunction('ease-in');  }
  16.4% {
		@include translate(0.0677em, 0);
		@include animationTimingFunction('ease-in');  }
  18.6% {
		@include translate(0.042em, 0);
		@include animationTimingFunction('ease-in');  }
  5%, 11%, 15%, 17.4%, 19.6% {
		@include translate(0, 0);
		@include animationTimingFunction('ease-out');
  }
  20%, 100% {
		@include translate(0, 0);
		@include animationTimingFunction('ease-out');
  }
}
@keyframes bounce-right {
  0% {
		@include translate(0.5em, 0);
		@include animationTimingFunction('ease-in');
  }
  8% {
		@include translate(0.27em, 0);
		@include animationTimingFunction('ease-in');  }
  13% {
		@include translate(0.135em, 0);
		@include animationTimingFunction('ease-in');  }
  16.4% {
		@include translate(0.0677em, 0);
		@include animationTimingFunction('ease-in');  }
  18.6% {
		@include translate(0.042em, 0);
		@include animationTimingFunction('ease-in');  }
  5%, 11%, 15%, 17.4%, 19.6% {
		@include translate(0, 0);
		@include animationTimingFunction('ease-out');
  }
  20%, 100% {
		@include translate(0, 0);
		@include animationTimingFunction('ease-out');
  }
}


@-webkit-keyframes glowPulse {
	0% {@include box-shadow(0, 0, 0.9em, 0.1em, white);}
	50% {@include box-shadow(0, 0, 0em, 0em, white);}
	100% {@include box-shadow(0, 0, 0.9em, 0.1em, white);}
}
@keyframes glowPulse {
	0% {@include box-shadow(0, 0, 0.9em, 0.1em, white);}
	50% {@include box-shadow(0, 0, 0em, 0em, white);}
	100% {@include box-shadow(0, 0, 0.9em, 0.1em, white);}
}

@-webkit-keyframes textGlow {
	0% {text-shadow: 0em 0em 0.25em white, 0em 0em 0.75em white;}
	50% {text-shadow: 0em 0em 0em white, 0em 0em 0em white;}
	100% {text-shadow: 0em 0em 0.25em white, 0em 0em 0.75em white;}
}
@keyframes textGlow {
	0% {text-shadow: 0em 0em 0.25em white, 0em 0em 0.75em white;}
	50% {text-shadow: 0em 0em 0em white, 0em 0em 0em white;}
	100% {text-shadow: 0em 0em 0.25em white, 0em 0em 0.75em white;}
}

@-webkit-keyframes boxGlow {
	0% {@include box-shadow(0, 0, 0.9em, 0.1em, #E23D36);}
	50% {@include box-shadow(0, 0, 0em, 0em, #E23D36);}
	100% {@include box-shadow(0, 0, 0.9em, 0.1em, #E23D36);}
}
@keyframes boxGlow {
	0% {@include box-shadow(0, 0, 0.9em, 0.1em, #E23D36);}
	50% {@include box-shadow(0, 0, 0em, 0em, #E23D36);}
	100% {@include box-shadow(0, 0, 0.9em, 0.1em, #E23D36);}
}

@-webkit-keyframes triggeredThreat {
	0% {
		color: white;
		background-color: #de1d24;
		background-image: none;
		text-shadow: none;
	}
	25% {
		color: white;
		background-color: #de1d24;
		background-image: none;
		text-shadow: 0em 0em 0.25em white, 0em 0em 0.75em white;
	}
	50% {
		color: white;
		background-color: #de1d24;
		background-image: none;
		text-shadow: none;
	}
	75% {
		color: white;
		background-color: #de1d24;
		background-image: none;
		text-shadow: 0em 0em 0.25em white, 0em 0em 0.75em white;
	}
	100% {
		color: white;
		background-color: #de1d24;
		background-image: none;
		text-shadow: none;
	}
}
@keyframes triggeredThreat {
	0% {
		color: white;
		background-color: #de1d24;
		background-image: none;
		text-shadow: none;
	}
	25% {
		color: white;
		background-color: #de1d24;
		background-image: none;
		text-shadow: 0em 0em 0.25em white, 0em 0em 0.75em white;
	}
	50% {
		color: white;
		background-color: #de1d24;
		background-image: none;
		text-shadow: none;
	}
	75% {
		color: white;
		background-color: #de1d24;
		background-image: none;
		text-shadow: 0em 0em 0.25em white, 0em 0em 0.75em white;
	}
	100% {
		color: white;
		background-color: #de1d24;
		background-image: none;
		text-shadow: none;
	}
}

@-webkit-keyframes wobble {
  0% {@include translateAndRotate();}
  1% {@include translateAndRotate(-0.1em, 0, 0, -2deg);}
  2% {@include translateAndRotate(0.2em, 0, 0, 1deg);}
  3% {@include translateAndRotate(-0.1em, 0, 0, -1deg);}
  4% {@include translateAndRotate(0.1em, 0, 0, 0.5deg);}
  5% {@include translateAndRotate(-0.1em, 0, 0, -0.5deg);}
	6% {@include translateAndRotate();}
	7% {@include translateAndRotate();}
}
@keyframes wobble {
  0% {@include translateAndRotate();}
  1% {@include translateAndRotate(-0.1em, 0, 0, -2deg);}
  2% {@include translateAndRotate(0.2em, 0, 0, 1deg);}
  3% {@include translateAndRotate(-0.1em, 0, 0, -1deg);}
  4% {@include translateAndRotate(0.1em, 0, 0, 0.5deg);}
  5% {@include translateAndRotate(-0.1em, 0, 0, -0.5deg);}
	6% {@include translateAndRotate();}
	7% {@include translateAndRotate();}
}


@-webkit-keyframes borderBlink {    
	from, to {    
			border-color: white    
	}    
	50% {    
			border-color: red    
	}    
}    
@keyframes borderBlink {    
	from, to {    
			border-color: white    
	}    
	50% {    
			border-color: red    
	}    
}