@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.EventCardSTBack {
	position: relative;
	width: 100%;
	min-height: 100%;
	text-align: left;
	background-image: linear-gradient(#3d5762, #213640);
	border-radius: 0.25em;


	.EventCardSTBack-header {
		width: 100%;
		height: 2.4em;
		padding: 0 0.6em;
		background-color: #288e73;
		border-top-left-radius: 0.25em;
		border-top-right-radius: 0.25em;
		@include flex('left', 'center');
		.EventCardSTBack-title {
			line-height: 1.2;
			font-weight: bold;
			text-transform: uppercase;
		}
	}

	.EventCardSTBack-body {
		padding: 0.8em 0.6em;
		border-bottom-left-radius: 0.25em;
		border-bottom-right-radius: 0.25em;
		.EventCardSTBack-text {
			p {margin: 0;}
			font-size: 0.75em;
		}
		.EventCardSTBack-effect {
			width: 100%;
			margin-bottom: 0.5em;
			padding-bottom: 0.75em;
			border-bottom: 1px dashed #000000;	
			&.oneline {
				display: inline-block;
				vertical-align: top;
				width: 50%;
				margin-bottom: 0;
			}
			&.good {
				.EventCardSTBack-effectValue {
					background-color: $green-light;
					&.time,
					&.cost {
						color: white;
						background-color: $green-light;
					}
				}
			}
			&.dirtyDozen {
				padding-bottom: 0;
				border-style: none;
				.EventCardSTBack-effectText span {
					text-transform: uppercase;
					font-weight: 700;
				}
			}
			.EventCardSTBack-effectText {
				width: 100%;
				font-size: 0.75em;
				margin-bottom: 0.25em;
				strong {
					text-transform: uppercase;
				}
			}
			.EventCardSTBack-effectValue {
				display: inline-block;
				vertical-align: middle;
				width: 1.66em;
				height: 1.66em;
				line-height: 1.66em;
				border-radius: 2.5em;
				color: white;
				background-color: $red;
				text-align: center;
				font-weight: 700;
				@include  box-shadow(0, 0, 0.3em, 0.1em, rgba(0,0,0,0.2));
				&.time {background-color: $blue;}
				&.cost {
					color: $black;
					background-color: #E8C039;
				}
				span {
					font-size: 0.88em;
				}
			}
	
			.EventCardSTBack-effectTitle {
				display: inline-block;
				vertical-align: middle;
				width: calc(100% - 1.66em);
				padding-left: 0.4em;
				line-height: 1em;
				font-weight: 600;
				font-style: italic;
				text-align: left;
				span {
					font-size: 0.88em;
				}
			}
			.EventCardSTBack-effectDD {
				width: 6.83em;
				height: 2em;
				margin: 0.5em 0;
				background-color: rgba(#1C282E, 0.5);
				border-radius: 0.33em;
				background-color: #1C282E;
				border: 1px solid rgba(#EBD03C, 0.75);
				color: #EBD03C;
				@include no-select();
				span {
					display: block;
					width: 100%;
					height: 100%;
					@include flex('center', 'center');
					font-size: 0.75em;
					font-weight: 600;
					text-align: center;
					line-height: 1.15;
					padding: 0.25em;
				}
			}
		}


		.EventCardSTBack-option {
			padding-bottom: 0.75em;
			border-bottom: 1px dashed #000000;
			margin-bottom: 0.5em;
			.EventCardSTBack-optionTitle {
				font-weight: bold;
			}
			.EventCardSTBack-optionText {
				padding: 0 0 0 0.25em;
				font-size: 0.75em;
				p {margin: 0;}
			}
		}
	}
}
