@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CrewCarousel {
	position: relative;
	width: 100%;
	height: 19em;
	margin-top: 6em;
	&.st {
		.CrewCarousel-card {
			&--focus {height: 16.25em;}
		}
	}
}

.CrewCarousel-cardWrap {
	position: absolute;
	height: 100%;
	width: 12em;
	left: calc(50% - 9.5em);
	padding: 0;
	will-change: transform;
	@include flex('center','center');
	&--focus {
		width: 19em;	
	}
}

.CrewCarousel-card {
	position: relative;
	width: 9.25em;
	height: 11.75em;
	text-align: left;	
	will-change: transform;
	transition: width 0.25s ease-in-out, height 0.25s ease-in-out;
	&--focus {
		width: 16.25em;
		height: 100%;
	}
}


.CrewCarousel-prevBtn,
.CrewCarousel-nextBtn {
	position: absolute;	
	top: 3.5125em;
	width: 4.75em;
	height: 4.75em;
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	cursor: pointer;
}

.CrewCarousel-prevBtn {
	left: 1em;
	background-image: url('../../assets/images/icon-prev-btn.svg');
}
.CrewCarousel-nextBtn {
	right: 1em;
	background-image: url('../../assets/images/icon-next-btn.svg');
}