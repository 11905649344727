@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.GroupPlayers {
	position: relative;
	text-align: left;
	
	.GroupPlayers-player {
		display: block;
		color: white;
	}
}
