@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CrewCardSTFlippable {
	position: relative;
	width: 100%;
	height: 100%;
	&.clickable {
		cursor: pointer;
	}
	&.flippable {
		cursor: pointer;
		// perspective: 1000px;
		// @include rotationStyle('preserve-3d');
		-webkit-transform: translateZ(1000px);
		transform: translateZ(1000px);
		.CrewCardSTFlippable-container {
			perspective: 1000px;
			@include rotationStyle('preserve-3d');
		}
	}

	.CrewCardSTFlippable-container {
		position: relative;
		width: 100%;
		height: 100%;
		-webkit-backface-visibility: hidden; /* Safari */
		backface-visibility: hidden;
		z-index: 1;
		&.back {
			.CrewCardSTFlippable-front {transform: rotateY(180deg);}
			.CrewCardSTFlippable-back {transform: rotateY(0deg);}
		}
		.CrewCardSTFlippable-front,
		.CrewCardSTFlippable-back {
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 0.33em;
			-webkit-backface-visibility: hidden; /* Safari */
			backface-visibility: hidden;
			z-index: 1;
			transition: transform 0.5s ease-out;
			@include rotationStyle('preserve-3d');
			@include box-shadow(0, 0.11em, 0.667em, 0em, rgba(0, 0, 0, 0.5));
		}
		&.noAnimation {
			.CrewCardSTFlippable-front,
			.CrewCardSTFlippable-back {
				transition-duration: 0s;
			}
		}
		.CrewCardSTFlippable-front {
			-webkit-transform: rotateY(0deg);
			transform: rotateY(0deg);
		}
		.CrewCardSTFlippable-back {
			-webkit-transform: rotateY(-180deg);
			transform: rotateY(-180deg);
		}
	}
}

