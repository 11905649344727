@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.GroupsPopup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(white, 0.12);
	backdrop-filter: blur(4px);
	@include flex('center', 'center');
	z-index: 1000;	
	.GroupsPopup-content {
		position: relative;
		width: 48em;
		height: 26.5em;
		background: rgba($black, 0.84);
		border-radius: 0.25em;
		background-color: #213640;
		z-index: 20;
		.GroupsPopup-closeBtn {
			position: absolute;
			top: 0;
			right: 0;
			width: 3.65em;
			height: 3.65em;
			background-image: url('../../assets/images/icon-close.svg');
			background-size: 1.25em auto;
			background-position: center center;
			background-repeat: no-repeat;
			cursor: pointer;
		}
		.GroupsPopup-title {
			height: 2.56em;
			background-color: #169D69;
			color: white;
			border-radius: 0.2em 0.2em 0 0;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: 700;
			font-style: italic;
			font-size: 1.389em;
		}
		.GroupsPopup-body {
			padding: 1em 2.5em;
			height: calc(100% - 3.56em);
			display: grid;
			overflow: auto;
			@include custom-scrollbar(0.5em, $black, $grey);
  		grid-template-columns: repeat(3, 1fr);
  		grid-auto-rows: 1fr;
  		grid-column-gap: 1em;
			grid-row-gap: 2em;
			text-align: left;
			.GroupsPopup-group {
				overflow: hidden;
				.GroupsPopup-groupTitle {
					font-size: 1.25em;
					font-weight: 900;
					font-style: italic;
					margin-bottom: 1em;
				}
			}
		}
	}
}
