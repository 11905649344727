@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.DirtyDozen {
	position: relative;
	width: 14.5em;
	height: 2.25em;
	margin-bottom: 0.75em;
	.DirtyDozen-value {
		position: absolute;
		left: 0;
		top: 0;
		width: 2.25em;
		height: 2.25em;
		color: white;
		background-color: $grey;
		border: 2px solid $grey;
		&.active{
			background-color: $orange-light;
			border: 2px solid $orange-light;
		}
		border-radius: 2.25em;
		text-align: center;
		line-height: 2em;
		font-weight: 700;
	}
	.DirtyDozen-title {
		width: 100%;
		height: 100%;
		padding-left: 3.35em;
		line-height: 2.25em;
		font-weight: 600;
		font-style: italic;
		text-align: left;
		white-space: nowrap;
		&.color {
			color: $orange-light;
		}
	}
	&.highlight {
		.DirtyDozen-value {
			animation-name: glowPulse;
			animation-duration: 1s;
			animation-iteration-count: 2;
			animation-fill-mode: forwards;
			background-color: $orange-light;
			border: 2px solid $orange-light;
		}
	}	
}