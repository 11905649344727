@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Logo {
	position: absolute;
	top: 1em;
	left: 2em;
	width: 12em;
	height: 3.89em;
	background-image: url('../../assets/images/logo.svg');
	background-size: contain;
	background-position: center left;
	background-repeat: no-repeat;
	cursor: pointer;

	&--landingpage {
		position: relative;
		background-position: center center;
		width: 31.5em;
		height: 9em;
		margin: 1em auto 3em auto;
		top: auto;
		left: auto;
	}

	&--login {
		position: relative;
		background-position: center center;
		width: 100%;
		height: 6em;
		margin: 0em auto 0.5em auto;
		top: auto;
		left: auto;
		cursor: default;
	}
}