@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.EventCardCRMBack {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: left;
	background-image: linear-gradient(#3d5762, #213640);
	border-radius: 0.25em;
	&.threat,
	&.triggersCriticalEffect {
		.EventCardCRMBack-header {
			background: linear-gradient($orange-light, $orange);
			color: $black;
		}
	}
	&.critical {
		background-image: linear-gradient(#e85e58, #e23d36);
		.EventCardCRMBack-header {
			color: white;
			background-color: #de1d24;
			background-image: none;
		}
	}

	.EventCardCRMBack-header {
		width: 100%;
		height: 2.45em;
		padding: 0 0.5em;
		background-color: #288e73;
		border-top-left-radius: 0.33em;
		border-top-right-radius: 0.33em;
		@include flex('left', 'center');
		.EventCardCRMBack-title {
			font-size: 0.85em;
			font-weight: 600;
			line-height: 1.2;
		}
	}

	.EventCardCRMBack-body {
		padding: 0.8em 0.6em;
		border-bottom-left-radius: 0.33em;
		border-bottom-right-radius: 0.33em;
		.EventCardCRMBack-text {
			p {
				margin: 0;
			}
			font-size: 0.75em;
		}

		.EventCardCRMBack-effects {
			width: 100%;
			padding: 0.5em 0.75em;
			.EventCardCRMBack-effect {
				width: 100%;
				margin: 0.5em 0;
				@include flex('flex-start', 'center');
			}
			.EventCardCRMBack-effectValue {
				display: inline-block;
				vertical-align: middle;
				width: 2em;
				height: 2em;
				line-height: 2em;
				border-radius: 2.5em;
				color: white;
				background-color: $red;
				text-align: center;
				font-weight: 700;
				@include  box-shadow(0, 0, 0.3em, 0.1em, rgba(0,0,0,0.2));
				&.blue {background-color: $blue;}
				&.yellow {
					color: $black;
					background-color: #E8C039;
				}
			}

			.EventCardCRMBack-effectTitle {
				display: inline-block;
				vertical-align: middle;
				width: calc(100% - 2em);
				padding-left: 0.4em;
				line-height: 1em;
				font-weight: 600;
				font-style: italic;
				text-align: left;
			}
		}
	}
}
