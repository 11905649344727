@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Facilitator {

	.Facilitator-saveBtn,
	.Facilitator-deleteBtn,
	.Facilitator-statusBtn {
		display: inline-block;
		margin-right: 1em;
		width: 10em;
		text-align: center;
		padding: 0.5em 0;
		color: white;
		background-color: $green;
		border: 1px solid white;
		opacity: 0.5;
		cursor: not-allowed;
		&.active {
			opacity: 1;
			cursor: pointer;
			&:hover,
			&:active {
				background-color: darken($green, 5%);
			}
		}
		&.saving,
		&.deleting {
			background-size: auto 80%;
			background-position: center right;
			background-repeat: no-repeat;	
			background-image: url('../../../assets/images/icon-loading-white.svg');
		}
	}

	.Facilitator-deleteBtn {
		background-color: $red;
		&.active {
			&:hover,
			&:active {
				background-color: darken($red, 5%);
			}
		}
	}

	.Facilitator-statusBtn {
		background-color: $yellow;
		&.active {
			&:hover,
			&:active {
				background-color: darken($yellow, 5%);
			}
		}
	}


	.Facilitator-title {
		font-size: 1.1em;
		text-transform: uppercase;
		font-style: italic;
		font-weight: bold;
		margin: 2em 0 0.5em 0;
	}
	.Facilitator-facilitatorInfoContainer {
		width: 100%;
		@include flex('space-between', 'top');
		margin-top: 2em;
		.Facilitator-facilitatorInfo {
			display: inline-block;
			vertical-align: top;
			width: 25em;
			margin-right: 2em;
			.Facilitator-title { 
				margin-top: 0;
			}
			.Facilitator-facilitatorName {
				width: 100%;
				margin-bottom: 1em;
				input {
					width: 100%;
					font-size: 1.25em;
					padding: 0.2em;
					background-color: transparent;
					border: none;
					color: white;
					&::placeholder {color: rgba(255, 255, 255, 0.5)}
					&:hover,
					&:focus,
					&:active {
						background-color: rgba(white, 0.2);
						border: none;
						outline: none;
					}
				}
			}
			.Facilitator-facilitatorEmail {
				font-size: 1.25em;
				padding: 0.2em 0;
			}
			.Facilitator-status {
				display: inline-block;
				font-size: 1.25em;
				padding: 0.2em 0;
				margin-right: 2em;
				color: $red;
				&.enabled {color: $green;}
				span {
					color: $yellow;
				}
			}
		}
	}





	.Facilitator-games {
		@include flex('left', 'top');
		.Facilitator-game {
			display: inline-block;
			margin-right: 1em;
			.Facilitator-gameHeader {
				background-color: #213640;
				border-radius: 0.25em 0.25 0 0;
				height: 3em;
				padding: 0 2em 0 2em;
				@include flex('center', 'center');
				.Facilitator-gameCheckbox {
					display: inline-block;
					vertical-align: middle;
					width: 1.5em;
					height: 1.5em;
					border: 1px solid white;
					margin-right: 1em;
					cursor: pointer;
					&.checked {
						background-image: url('../../../assets/images/icon-check-green.svg');
						background-size: contain;
						background-position: center center;
						background-repeat: no-repeat;
					}
				}
				.Facilitator-gameTitle {
					display: inline-block;
					vertical-align: middle;

				}
			}
			.Facilitator-gameScenarios {
				height: 3em;
				background: rgba(#213640, 0.8);
				@include flex('space-around', 'center');
				white-space: nowrap;
				.Facilitator-gameScenarioCheckbox {
					display: inline-block;
					vertical-align: middle;
					width: 1em;
					height: 1em;
					border: 1px solid white;
					margin-right: 0.25em;
					cursor: pointer;
					&.checked {
						background-image: url('../../../assets/images/icon-check-green.svg');
						background-size: contain;
						background-position: center center;
						background-repeat: no-repeat;
					}
				}
				.Facilitator-gameScenarioTitle {
					display: inline-block;
					vertical-align: middle;

				}
			}
		}
	}

	

}