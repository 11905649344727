@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';

.GameBoardSTDnDItem {
	position: relative;
	border-radius: 0.33em;
	text-align: center;
	background-color: #213640;
	@include no-select();
	&--small {
		width: 2.5em;
		height: 2em;
		margin-right: 0.5em;
		display: inline-block;
		vertical-align: middle;
		border: 1.5px solid black;		
		padding-top: 0.1em;
		svg {
			width: 75%;
			vertical-align: middle;
		}		
	}
	&--big {
		width: calc(6.5em - 1.1em);
		padding-top: 0.25em;
		height: 3.7em;
		border: 2px solid black;
		margin-bottom: 0.25em;
		svg {
			width: 100%;
			height: 100%;
		}
	}

	&--draggable {cursor: move;}

	&.blue {
		border-color: #1380C9;
		svg {fill: #1380C9;}
	}
	&.red {
			border-color: #D93B36;
			svg {fill: #D93B36;}
	}
	&.orange {
			border-color: #E8963A;
			svg {fill: #E8963A;}
	}
	&.green {
			border-color: #189D68;
			svg {fill: #189D68;}
	}
	&.white {
			border-color: #F4F5F6;
			svg {fill: #F4F5F6;}
	}
}
