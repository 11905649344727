@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';

.SettingsHeader {
	position: relative;
	width: 100%;
	height: 3.85em;
	color: white;
	background-color: $black;
	@include flex(space-between);
	.SettingsHeader-left,
	.SettingsHeader-center,
	.SettingsHeader-right {
		// width: 30%;
		height: 100%;
		display: inline-block;
		vertical-align: top;
	}
	.SettingsHeader-left {
		text-align: left;
		padding: 0 1em;
		.SettingsHeader-content {
			width: 100%;
			height: 100%;
			@include flex('flex-start', 'center');
		}
	}
	.SettingsHeader-center {
		width: 40%;		
		text-align: center;
		font-size: 1.38em;
		line-height: 2.8;
		font-weight: bold;
		font-style: italic;
		white-space: nowrap;
		.SettingsHeader-title {
			span {
				color: $mayday-yellow
			}
		}
		&.double {
			@include flex(space-between, center);
			font-style: normal;
			font-size: 0.85em;
			flex-grow: 1;
    		padding: 0 2em;
			.SettingsHeader-gametitle{
				strong{font-weight: normal;}
				span{font-weight: bold;}
			}
			.SettingsHeader-gameurl{
				strong{font-weight: bold;}
				span{font-weight: normal}
			}
			.SettingsHeader-gamecode{
				strong{font-weight: bold;}
				span{font-weight: normal}
			}
			// line-height: 1;
			// padding: 0.6em 0;
			// span {
			// 	display: block;
			// 	font-size: 0.6em;
			// 	margin-top: 0.2em;
			// 	font-weight: normal;
			// 	font-style: normal;
			// }
		}
	}
	.SettingsHeader-right {
		text-align: right;
		padding: 1em;
		.SettingsHeader-email,
		.SettingsHeader-emailAndLanguage {
			display: inline-block;
			vertical-align: middle;
			font-size: 0.85em;
			margin-right: 1em;
			@include no-select();
		}
		.SettingsHeader-logoutBtn {
			display: inline-block;
			vertical-align: middle;
			width: 2em;
			height: 1.85em;
			margin-left: 1em;
			background-image: url('../../assets/images/icon-logout-light.svg');
			background-position: center right;
			background-size: auto 100%;
			background-repeat: no-repeat;
			cursor: pointer;
		}
		&.language  {
			padding: 0.5em 1em;
		}
	}
}