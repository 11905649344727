@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CommunicationResult {
	position: relative;
	height: 100%;
	background-image: url('../../assets/images/background2.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	padding: 7.25em 2em 2em 2em;
	color: white;
	.InfoBox {
		position: fixed;
		width: 50vw;
		height: 50vh;
		left: calc(50vw - 25%);
		top: calc(50vh - 25%);
	}
	.CommunicationResult-groups {
		display: inline-block;
		vertical-align: top;
		width: 11.25em;
		max-height: calc(100vh - 9em);
		background-color: rgba(#213640, 0.4);
		border-radius: 0.25em 0 0 0.25em;
		.CommunicationResult-header {
			width: 100%;
			height: 2.68em;
			background-color: $green;
			border-radius: 0.25em 0 0 0;
			cursor: pointer;
			@include flex('center', 'center');
			span {
				font-size: 1.25em;
				font-weight: 600;
			}
		}
		.CommunicationResult-body {
			width: 100%;
			border-right: 0.2em solid $white-seeThrough;
			.CommunicationResult-group {
				position: relative;
				height: 8em;
				padding: 1em 0.45em;
				border-bottom: 0.2em solid $white-seeThrough;
				@include flex('center', 'center');
				&:last-child {border-bottom: none;}
				&:hover {
					.CommunicationResult-groupPlayers {display: block;}
				}
				.CommunicationResult-groupTitle {
					width: 100%;
					height: 100%;
					border-radius: 0.25em;
					border: 0.1em solid #213640;
					background-color: rgba(#213640, 0.4);
					@include flex('center', 'center');
					span {
						font-size: 1.25em;
						font-weight: 600;
					}
				}
				.CommunicationResult-groupPlayers {
					display: none;
					position: absolute;
					left: 12em;
					min-width: 12em;
					background-color: #213640;
					border-radius: 0.25em;
					padding: 0.5em;
					z-index: 10;
				}
			}
		}
	}
	.CommunicationResult-groupAnswers {
		display: inline-block;
		vertical-align: top;
		width: calc(100% - 11.25em);
		max-height: calc(100vh - 9em);
		background-color: rgba(#213640, 0.4);
		border-radius: 0 0.25em 0.25em 0;	
		overflow: auto hidden;
		@include custom-scrollbar(0.5em, $black, $grey);
		.CommunicationResult-header {
			height: 2.68em;
			background-color: $green;
			white-space: nowrap;
			.CommunicationResult-headerText {
				display: inline-block;
				vertical-align: top;
				width: 30em;
				height: 100%;
				text-align: center;
				background-color: $green;
				p{margin:0;}
				span {
					font-size: 1.25em;
					line-height: 2.144;
					font-weight: 600;
				}
			}
		}
		.CommunicationResult-body {
			.CommunicationResult-groupRow {
				width: 100%;
				height: 8em;
				white-space: nowrap;
				&:last-child {
					height: 7.5em;
					.CommunicationResult-groupAnswerContainer {
						border-bottom: none;
					}
				}
				.CommunicationResult-groupAnswerContainer {
					display: inline-block;
					vertical-align: top;					
					width: 30em;
					height: 100%;
					padding: 1em;
					border-bottom: 0.2em solid $white-seeThrough;
					.CommunicationResult-groupAnswer {
						width: 100%;
						height: 100%;
						background-color: rgba(#213640, 0.4);
						padding: .3em;
						border-radius: 0.25em;
						border: 0.1em solid $black;
						overflow: hidden auto;
						@include flex('flex-start', 'flex-start');
						@include custom-scrollbar(0.5em, $black, $white-seeThrough);
						span {
							font-size: 0.8em;
							font-weight: 600;
							white-space: pre-wrap;
							padding: 0.5em;
						}
					}
				}
			}
		}
	}
}
