@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.ThreatPopup-popup {
	position: fixed;
	background-color: rgba(255, 255, 255, 0.1);
	z-index: 20;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	@include blurBackground(2px);
	@include flex('center', 'center');
	.ThreatPopup-content {
		position: relative;
		width: 18.25em;
		background: linear-gradient($orange-light, $orange);
		border-radius: 0.25em;
		z-index: 20;
		@include box-shadow(0, 0.11em, 0.67em, 0em, rgba(0, 0, 0, 0.5));
		color: $black;
		.ThreatPopup-closeBtn {
			position: absolute;
			top: -2.5em;
			left: calc(100% - 0.5em);
			width: 3.65em;
			height: 3.65em;
			background-image: url('../../assets/images/icon-close.svg');
			background-size: 1.25em auto;
			background-position: center center;
			background-repeat: no-repeat;
			cursor: pointer;
		}
		.ThreatPopup-title {
			border-radius: 0.25em 0.25em 0 0;
			display: flex;
			align-items: center;
			height: 3.56em;
			font-weight: 700;
			background-color: $orange;
			padding: 0 1.11em;
		}
		.ThreatPopup-body {
			padding: 1.11em;
			hr {
				height: 1px;
				border: none;
				color: #ce7c1f;
				background-color: #ce7c1f;
				margin: 1.56em 0;
			}
			p {
				margin: 0;
			}
			.ThreatPopup-text {
				font-weight: 700;
			}
		}
	}
}