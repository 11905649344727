@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CrewPopup {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: rgba(170, 79, 79, 0.1);
	z-index: 20;
	@include blurBackground(2px);
	@include flex('center', 'center');
	.CrewPopup-content {
		position: relative;
		width: 51em;
		background-color: rgba(#213640, 0.85);
		border-radius: 0.3em;
		.CrewPopup-header {
			position: relative;
			height: 3.5em;
			background-color: #3d5762;
			border-top-left-radius: 0.3em;
			border-top-right-radius: 0.3em;
			@include flex('center', 'center');
			.CrewPopup-title {
				font-size: 1.55em;
				line-height: 1;
				color: white;
				font-style: italic;
				font-weight: 800;
				text-align: center;
			}
			.CrewPopup-closeBtn {
				position: absolute;
				top: 0;
				right: 0;
				width: 3.65em;
				height: 3.65em;
				background-image: url('../../assets/images/icon-close.svg');
				background-size: 1.25em auto;
				background-position: center center;
				background-repeat: no-repeat;
				cursor: pointer;
			}
		}
		.CrewPopup-body {
			position: relative;
			padding: 2em 1.5em;
			@include flex('space-between', 'flex-start');
			.CrewPopup-selectedSlot {
				position: relative;
				display: inline-block;
				vertical-align: top;
				width: 16.25em;
				color: white;
			}
			.CrewPopup-crew {
				width: calc(100% - 16.25em);
				display: inline-block;
				vertical-align: top;
				margin-bottom: 3em;
				text-align: center;
				color: white;

				.CrewPopup-slot {
					position: relative;
					display: inline-block;
					vertical-align: top;
					width: 9.25em;
					height: 11.75em;
					margin: 0 0.5em 1em 0.5em;
					background-image: linear-gradient(rgba(black, 0.24), rgba(black, 0.07));
					border-radius: 0.33em;
					text-align: center;
					cursor: pointer;
					@include box-shadow(0, 0.11em, 0.667em, 0em, rgba(0, 0, 0, 0.5));
					.CrewPopup-slotTitle {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						@include flex('center', 'center');
						@include no-select();
						font-size: 1.25em;
						font-style: italic;
						font-weight: 800;
						color: white;
					}
					&--removed {
						@include opacity(0.3);
					}
				}
			}
		}
	}
}
