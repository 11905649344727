@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.DisableFacilitatorPopup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(white, 0.12);
	backdrop-filter: blur(4px);
	@include flex('center', 'center');

	.DisableFacilitatorPopup-content {
		position: relative;
		width: 20em;
		height: 20em;
		background-color: #213640;
		border-radius: 0.25em;
		overflow: auto;
		.DisableFacilitatorPopup-header {
			position: relative;
			height: 3em;
			background-color: $green;
			border-top-left-radius: 0.25em;
			border-top-right-radius: 0.25em;
			padding: 1em;
			.DisableFacilitatorPopup-title {
				font-size: 1.25em;
				line-height: 1;
				color: white;
				font-style: italic;
				font-weight: 800;
				text-align: left;
			}
			.DisableFacilitatorPopup-closeBtn {
				position: absolute;
				top: 0;
				right: 0;
				width: 3em;
				height: 3em;
				background-image: url('../../../assets/images/icon-close.svg');
				background-size: 1.25em auto;
				background-position: center center;
				background-repeat: no-repeat;
				cursor: pointer;
			}
		}

		.DisableFacilitatorPopup-body {
			padding: 2em;
			span {
				font-weight: bold;
			}
			input {
				width: 100%;
				height: 1em;
				border: none;
				border-radius: 0.25em;
				display: block;
				padding: 1em 1em 1em 0.5em;
				margin: auto;
				margin-bottom: 1.2em;
				background-color: white;
				color: $black;
				&::placeholder {color: rgba(255, 255, 255, 0.5)}
			}
			.DisableFacilitatorPopup-errMsg {
				font-size: 0.85em;
				height: 2em;
				color: $red;
			}

			.DisableFacilitatorPopup-deleteBtn,
			.DisableFacilitatorPopup-cancelBtn,
			.DisableFacilitatorPopup-okBtn {
				display: inline-block;
				background-color: $red;
				padding: 0.5em 1.5em;
				border-radius: 0.25em;
				border: 1px solid white;
				font-weight: bold;
				margin-bottom: 2em;
				cursor: pointer;
				&:hover {background-color: darken($red, 5%);}
				&.disabling {
					background-image: url('../../../assets/images/icon-loading-white.svg');
					background-size: auto 80%;
					background-position: right center;
					background-repeat: no-repeat;
				}
			}
			.DisableFacilitatorPopup-cancelBtn {
				margin-left: 2em;
				background-color: $yellow;
				&:hover {background-color: darken($yellow, 5%);}
			}
			.DisableFacilitatorPopup-okBtn {
				display: block;
				margin: auto;
				width: 5em;
				background-color: #0080c9;
				&:hover {background-color: darken(#0080c9, 5%);}
			}
		}
	}
}




