@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';

.GameBoardIntro {
	position: relative;
	min-height: 100%;
	background-image: url('../../assets/images/background2.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	padding: 8em 1em 3em 1em;
	color: white;
}