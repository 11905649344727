@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Players {
	width: 30em;
	background: rgba(#213640, 0.8);
	border-radius: 0 0.25em 0.25em 0;
	margin-bottom: 1em;
	.Players-header {
		width: 100%;
		height: 3em;
		background-color: #213640;
		border-radius: 0.25em 0 0 0;
		@include flex('left', 'center');
		font-weight: bold;
		
	}

	.Players-body {
		width: 100%;
		padding: 0.5em 0;
		.Players-player {
			width: 100%;
			@include flex('left', 'center');
			&:hover {
				background-color: rgba(white, 0.05);
			}
			.Players-section.delete {
				cursor: pointer;
				background-image: url('../../../assets/images/icon-delete-red.svg');
				background-size: auto 100%;
				background-position: center center;
				background-repeat: no-repeat;
				&:hover {
					background-size: auto 110%;
				}
			}
		}
	}

	.Players-section {
		@include flex('left', 'center');
		width: 20%;
		height: 100%;
		text-align: center;
		padding: 0.3em 0.5em;
		&.name {width: 70%;}
		&.delete {
			width: 10%;
			height: 1em;
		}
	}
}