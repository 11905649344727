@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';

.CrewSelect {
	position: relative;
	min-height: 100%;
	background-image: url('../../assets/images/background2.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	color: white;
	overflow: auto;
	&.heli {
		background-position: top center;
		background-image: url('../../assets/images/background-clouds-dark.jpg');	
	}

	.CrewSelect-crewContainer {
		position: absolute;
		width: 100%;
		height: 100%;
		border-radius: 0.33em;
		overflow: hidden;
		@include flex('center', 'center');
		&--available {
			min-height: 40em;
		}
		&--green {background-color: rgba($green, 0.5);}
		&--red {background-color: rgba($red, 0.5);}
		.CrewSelect-content {
			width: 100%;
			min-height: 40em;
		}
	}
	
	.CrewSelect-dragLayer {
		pointer-events: none;
		position: absolute;
		left: 0;
		top: 0;
		width: 9.25em;
		height: 11.75em;
		&--big {
			left: 3.5em;
			top: 2.625em;
		}
	}
}

.CrewSelect-popup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(white, 0.12);
	backdrop-filter: blur(4px);
	@include flex('center','center');
	.CrewSelect-popupContent {
		position: relative;
		padding: 2em;
		width: 21em;
		height: 20em;
	}
	
	.CrewSelect-popupCloseBtn {
		position: absolute;
		top: 0;
		right: 0;
		width: 2em;
		height: 2em;
		background-image: url('../../assets/images/icon-close.svg');
		background-size: 1.25em auto;
		background-position: center center;
		background-repeat: no-repeat;
		cursor: pointer;
	}
}