@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.GameResult {
	position: relative;
	height: 100%;
	background-image: url('../../assets/images/background2.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	padding: 7.25em 2em 2em 2em;
	color: white;
	.GameResult-container {
		width: 94%;
		max-height: calc(100vh - 12em);
		overflow: auto;
		@include custom-scrollbar(0.5em, $black, $white-seeThrough);
		.GameResult-header {
			width: 100%;
			height: 2.75em;
			border-radius: 0.25em 0.25em 0 0;
			display: flex;
			justify-content: space-between;
			.GameResult-headerTitle {
				border-top-left-radius: 0.25em;
				border-top-right-radius: 0.25em;
				font-size: 1.25em;
				font-weight: 600;
				font-style: italic;
				width: 11.1em;
				line-height: 1;
				@include flex('center', 'center');
				&:nth-of-type(1) {
					background-color: $green; 
					width: 8.9em;
					border-right: 0.2em solid $green;
					cursor: pointer;
				}
				&:nth-of-type(2) {background-color: $orange-light; color: $grey;}
				&:nth-of-type(3) {background-color: $blue;}
				&:nth-of-type(4) {background-color: $red;}
				&:nth-of-type(5) {background-color: $grey-offset; width: 8.9em;}
			}
		}
		.GameResult-groupsWrapper {
			border-bottom-left-radius: 0.25em;
			border-bottom-right-radius: 0.25em;
			background-color: rgba(#213640, 0.4);
			.GameResult-groupRow {
				position: relative;
				width: 100%;
				height: 6em;
				display: flex;
				justify-content: space-between;
				border-top: 0.2em solid $white-seeThrough;
				&:first-child {border-top: none;}
				.GameResult-group {
					position: relative;
					height: 100%;
					width: 11.1em;
					padding: 0.3em;
					border-right: 0.25em solid $white-seeThrough;
					@include flex('center', 'center');
					box-sizing: border-box;
					&:hover {
						.GameResult-groupPlayers {display: block;}	
					}
					.GameResult-groupTitle {
						width: 100%;
						font-size: 1.25em;
						font-weight: 600;	
						border: 0.08em solid #213640;
						background-color: rgba(#213640, 0.4);
						border-radius: 0.25em;
						padding: 0.25em 0.5em;
					}
					.GameResult-groupPlayers {
						display: none;
						position: absolute;
						left: 12em;
						width: 11.1em;
						background-color: #213640;
						border-radius: 0.25em;
						padding: 0.5em;
					}
				}

				.GameResult-statValue {
					background-color: rgba(#213640, 0.4);
					height: 100%;
					width: 11.1em;
					font-size: 1.25em;
					font-weight: 600;
					@include flex('center', 'center');
					&.dd {cursor: pointer;}
					&:last-of-type {background-color: transparent; width: 8.9em;}
					&:nth-of-type(4) {
						span {						
							font-weight: 900;
							font-size: 1.3em;
						}
					}
				}
			}
			.goldMedal{
				position: absolute;
				top: calc(50% + 0.3em);
				@include translate(0, -50%);
				right: 0.3em;
				width: 4em;
			}
		}
	}
}