@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.DirtyDozenInfoPopup-popup {
	position: fixed;
	background-color: rgba(255, 255, 255, 0.1);
	z-index: 20;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	@include blurBackground(2px);
	@include flex('center', 'center');
	.DirtyDozenInfoPopup-content {
		position: relative;
		width: 40em;
		background: rgba($black, 0.84);
		border-radius: 0.25em;
		background-color: #213640;
		z-index: 20;
		.DirtyDozenInfoPopup-closeBtn {
			position: absolute;
			top: 0;
			right: 0;
			font-size: 1.389em;
			width: 2em;
			height: 2em;
			background-image: url('../../assets/images/icon-close.svg');
			background-size: 0.8em auto;
			background-position: center center;
			background-repeat: no-repeat;
			cursor: pointer;
		}
		.DirtyDozenInfoPopup-title {
			height: 2em;
			background-color: $orange-light;
			color: $black;
			border-radius: 0.2em 0.2em 0 0;
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: 700;
			font-style: italic;
			font-size: 1.389em;
		}
		.DirtyDozenInfoPopup-body {
			color: white;
			border: 2px solid $orange-light;
			padding: 2em 1.5em;
			.DirtyDozenInfoPopup-text {
				p {margin-top: 0;}
			}
			.DirtyDozenInfoPopup-subtitle {
				font-weight: bold;
			}
			.DirtyDozenInfoPopup-list {
				padding-left: 1.5em;
				.DirtyDozenInfoPopup-bullet {
					display:list-item;
				}
			}
			
		}
	}
}