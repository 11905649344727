@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Resources {
	display: inline-block;
	vertical-align: top;
	width: 14.55em;
	background-color: $black-seeThrough;
	border-radius: 0.25em;		
	margin-right: 3.25em;
	.Resources-title {
		height: 2em;
		border-radius: 0.25em 0.25em 0 0;	
		@include flex('center', 'center');
		background-color: $green;
		span {
			font-weight: 700;
			font-style: italic;
		}
	}
	.Resources-content {
		padding: 0.885em 1.25em;
		height: 3.56em;
		.Resources-resources {
			width: 100%;
			height: 100%;
			@include flex('space-between', 'center');
			.Resources-resource {
				width: 1.75em;
				height: 1.75em;
				background-color: white;
				&.placed {background-color: transparent;}
				&.locked {background-color: transparent;}
			}
		}
	}

	&.crm-helicopters {
		width: 13.55em;
		margin-right: 0.7em;
		.Resources-content {
			.Resources-resources {
				.Resources-resource {
					width: 1.25em;
					height: 1.25em;
				}
			}
		}
	}
}