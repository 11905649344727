@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';

.Communication {
	position: relative;
	min-height: 100%;
	background-image: url('../../assets/images/background2.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	padding: 6em 2em 1em 2em;
	color: white;
	&-container{
		@include flex();
		width:100%;
		height:100%;
	}
	&-form{
		width: 55%;
		height:100%;
		display: inline-block;
	}
	&-overview{
		width: 45%;
		margin-left:1em;
		display: inline-block;
		background-color: rgba(#213640, 0.4);
		color: white;
		border-radius: 0.33em;
		span{ 
			padding-left: 1em;
			border-left: 1px solid rgba(255,255,255,0.6);
			display: block;
		}
		&-header{
			background:$grey-offset;
			border-radius: 0.33em 0.33em 0 0;
			color: #fff;
			text-transform: uppercase;
			padding: 0.45em 1em;
			font-weight: bold;
			font-size: 1.2em;
		}
		&-content{
			max-height: calc(100vh - 10em);
			overflow-y: auto;
			overflow-y: auto;
			@include custom-scrollbar(0.5em, $black, $grey);
		}
		&-answer{
			padding:1em;
			strong {
				text-transform: capitalize;
			}
			p {
				white-space: pre-wrap;
				margin-top:0;
			}
		}
	}
}

.Communication-header {
	width: 100%;
	height: 2.67em;
	border-radius: 0.25em 0.25em 0 0;
	background-color: $green;
	padding: 0.7em 3.5em;
}

.Communication-questions {
	width: 100%;
	max-height: calc(100vh - 10em);
	background-color: rgba(#213640, 0.4);
	color: white;
	border-radius: 0 0 0.33em 0.33em;
	overflow: auto;
	@include custom-scrollbar(0.5em, $black, $grey);
}

.Communication-question {
	position: relative;
	width: 100%;
	height: 9em;
	padding: 1em 1em 1em 1em;
	border-bottom: 0.2em solid $white-seeThrough;
	&::first-letter {
		font-weight: 700;
	}
	&:last-of-type {
		border: none;
		border-radius: 0 0 0.33em 0.33em;
	}
}

.Communication-questionTitle {
	height: 100%;
	width: 25%;
	@include flex(center, center);
	position: relative;
	font-size: 1.55em;
	font-weight: 500;
	text-align: center;
	p{margin:0;}
}

.Communication-answer {
	position: absolute;
	top: 1em;
	right: 0;
    width: 75%;
	height: calc(100% - 2em);
	text-align: center;
	@include textarea-placeholder(white);
	.Communication-textarea {
		font-size: 0.88em;
		width: 90%;
		height: 100%;
		background-color: rgba($black-seeThrough, 0.4);
		resize: none;
		border-radius: 0.25em;
		border: 0.2em solid $black;
		color: $white;
		outline: none;
		padding: 0.8em;
		@include custom-scrollbar(0.5em, $black, $white-seeThrough);
	}
}