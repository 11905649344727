@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.ReflectionFP {
	display: inline-block;
	vertical-align: top;
	width: 27em;
	margin-top: 1em;
	.ReflectionFP-fp {
		position: relative;
		width: 24em;
		padding-left: 3em;
		height: 2.25em;
		margin-bottom: 0.667em;
		white-space: nowrap;
		&:last-child {margin-bottom: 0;}
		.ReflectionFP-position {
			position: absolute;
			left: 0;
			top: 0;
			width: 2.25em;
			height: 2.25em;
			border-radius: 2.235em;
			color: #EBD03C;
			background-color: #1C282E;
			border: 2px solid #EBD03C;
			text-align: center;
			font-weight: bold;
			@include flex('center', 'center');
			span {font-size: 1.55em;}
		}
	}

}