@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.GameBoardST {
	position: relative;
	height: 100%;
	// min-height: 38em;
	background-image: url('../../assets/images/background2.jpg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	color: white;
	// padding: 6em 1.75em 0 2.25em;
	// @include custom-scrollbar(0.5em, $black, $white-seeThrough);
	&-Disabled{
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		display: block;
		z-index: 1001;
	}
	.GameBoardST-header {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 5.56em;
		padding: 1em;
	}
	.GameBoardST-progress {
		display: inline-block;
		vertical-align: middle;
		height: 3.89em;
		width: 13.5em;
		background-color: rgba(#213640, 0.4);
		border-radius: 0.2em;
		span {
			font-size: 1.25em;
			line-height: 1;
			padding: 1.056em 1em;	
			font-style: italic;
			font-weight: 700;
			text-transform: uppercase;
		}
	}
	.GameBoardST-crew {
		padding: 6em 2em 0.5em 2em;
		.GameBoardST-slot {
			position: relative;
			display: inline-block;
			vertical-align: top;
			width: 13.5em;
			height: 13.85em;
			margin: 0.25em 0.5em;
			background-image: linear-gradient(rgba(black, 0.24), rgba(black, 0.07));
			border-radius: 0.33em;
			text-align: center;
			&:nth-child(1) {margin-left: 0;}
		}
	}
	.GameBoardST-mainContent {
		position: absolute;
		left: 0;
		right: 0;
		top: 21em;
		bottom:0em;
		min-height: 15em;
		padding: 0.5em 2em;
		padding-left:2.22em;
		overflow: visible auto;
		@include custom-scrollbar(0.5em, $black, $white-seeThrough);
		.GameBoardST-cards {
			display: inline-block;
			vertical-align: top;
			width: 100%;
			.GameBoardST-cardWrap {
				display: inline-block;
				vertical-align: top;
				width: calc(25% - 0.7em);
				// height: 30em;
				text-align: left;
				background-image: linear-gradient(rgba(black, 0.24), rgba(black, 0.07));
				border-radius: 0.25em;
				z-index: 1;
				margin: 0 0.7em 0.7em 0;
				&.shake {
					cursor: pointer;
					-webkit-animation: wobble 5s cubic-bezier(0.280, 0.840, 0.420, 1) 3s infinite forwards;
					animation: wobble 5s cubic-bezier(0.280, 0.840, 0.420, 1) 3s infinite forwards;
				}
			}
		}
	}
}

.GameBoardST-cards{
	position: relative;
	.GameBoardST-cardWrap {
		.EventCardST{
			@include transition(all, .5s, cubic-bezier(.25,0,.28,1));
		}
		&:nth-child(1) .EventCardST{
			@include transition-delay(1.55s);
			@include translate(calc(-100vw));
		}
		&:nth-child(2) .EventCardST{
			@include transition-delay(1.40s);
			@include translate(calc(-100vw));
		}
		&:nth-child(3) .EventCardST{
			@include transition-delay(1.25s);
			@include translate(calc(-100vw));
		}
		&:nth-child(4) .EventCardST{
			@include transition-delay(1.10s);
			@include translate(calc(-100vw));
		}
	}
	&.cardsDealed{
		.GameBoardST-cardWrap {
			.EventCardST {
				@include translate(0, 0);
				.EventCardST-container {
					animation-duration: 0.5s; 
					animation-timing-function: ease;
					animation-name: bounceOut;
				}
			}
			&:nth-child(1) .EventCardST .EventCardST-container {animation-delay: 2.05s;}
			&:nth-child(2) .EventCardST .EventCardST-container {animation-delay: 1.90s;}
			&:nth-child(3) .EventCardST .EventCardST-container {animation-delay: 1.75s;}
			&:nth-child(4) .EventCardST .EventCardST-container {animation-delay: 1.60s;}
			// &:nth-child(5) .EventCardST .EventCardST-container {animation-delay: .50s;}
			// &:nth-child(6) .EventCardST .EventCardST-container {animation-delay: .65s;}
			// &:nth-child(7) .EventCardST .EventCardST-container {animation-delay: .80s;}
			// &:nth-child(8) .EventCardST .EventCardST-container {animation-delay: .95s;}
		}
	}
}