@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.Loading {
	height: 100%;
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	grid-template-rows: 2.2fr auto 1fr;
	background-image: url('../../assets/images/background.jpg');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	.Loading-wrapper {
		position: fixed;
		top: calc(50vh - 5.56em);
		left: calc(50vw - 5.56em);
		background-image: url('../../assets/images/icon-loading.svg');
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		width: 11.11em;
		height: 11.11em;
		margin: 0 auto;
	}
}