@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.CrewFeedback {
	position: relative;
	min-height: 100%;
	background-image: url('../../assets/images/background2.jpg');
	background-size: cover;
	background-attachment: initial;
	background-position: center center;
	background-repeat: no-repeat;
	color: white;
	display: grid;
	grid-template-columns: 1fr 4fr 1fr 3fr 3fr;
	grid-template-rows: 5.55em 7fr;
}

.CrewFeedback-planeBackground {
	background-image: url('../../assets/images/background-plane.png');
	background-size: contain;
	background-attachment: initial;
	background-position: center center;
	background-repeat: no-repeat;
	grid-column-start: 1;
	grid-column-end: 4;
	grid-row-start: 2;
	grid-row-end: 2;
}

.CrewFeedback-selectedWrapper {
	grid-column-start: 2;
	grid-column-end: 2;
	grid-row-start: 2;
	grid-row-end: 2;
	width: 22.5em;
	margin: 0 auto;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-content: space-between;
	justify-content: space-around;
	.CrewFeedback-selectedCockpitCrew, .CrewFeedback-selectedCabinCrew {
		margin: 0 auto;
		.CrewFeedback-slot {
			position: relative;
			display: inline-block;
			vertical-align: top;
			width: 9.25em;
			height: 10.5em;
			margin: 0.25em 0.5em;
			background-image: linear-gradient(rgba(black, 0.24), rgba(black, 0.07));
			border-radius: 0.33em;
			text-align: center;
			&.shake {
				-webkit-animation: wobble 5s cubic-bezier(0.280, 0.840, 0.420, 1) 3s infinite forwards;
				animation: wobble 5s cubic-bezier(0.280, 0.840, 0.420, 1) 3s infinite forwards;

			}
			.CrewFeedback-slotTitle {
				position: absolute;
				top: 0; 
				left: 0;
				width: 100%;
				height: 100%;
				@include flex("center", "center");
				font-size: 1.75em;
				font-style: italic;
				font-weight: 800;
				color: rgba(black, 0.4);
			}
		}
	}
}

.CrewFeedback-selectedSlot {
	position: relative;
	grid-column-start: 3;
	grid-column-end: 5;
	grid-row-start: 2;
	grid-row-end: 2;
	width: 20em;
	margin: 0.75em auto;
	text-align: center;
	.CrewFeedback-instructions {
		position: absolute;
		top: 6.95em;
		left: calc(50% - 0.5*16.25em);
		width: 16.25em;
		height: 23em;
		background-image: linear-gradient(rgba(black, 0.25), rgba(black, 0.07));
		border-radius: 0.25em;
		padding: 1em;
		text-align: center;
		-webkit-transition: opacity 0.5s linear;
		transition: opacity 0.5s linear;
		&.hide {opacity: 0;}
		.CrewFeedback-instructionsTitle {
			font-weight: 800;
			font-style: italic;
		}
		.CrewFeedback-instructionsText {
			font-size: 0.85em;
		}
	}
	.CrewFeedback-selectedCrewContainer {
		position: relative;
		z-index: 10;
		padding-top: 1.4em;
	}
}

.CrewFeedback-dirtyDozens {
	grid-column-start: 5;
	grid-column-end: 5;
	grid-row-start: 2;
	grid-row-end: 2;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}