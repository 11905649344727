@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.EventCardSTFront {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: left;
	background-image: linear-gradient(#30ad87, #288e73);
	border-radius: 0.25em;
	color: $white;
	.EventCardSTFront-header {
		width: 100%;
		height: 2.4em;
		padding: 0 0.6em;
		background-color: #288e73;
		border-top-left-radius: 0.25em;
		border-top-right-radius: 0.25em;
		@include flex('left', 'center');
		.EventCardSTFront-title {
			line-height: 1.2;
			font-weight: bold;
			text-transform: uppercase;
		}
	}

	.EventCardSTFront-body {
		padding: 0.8em 0.6em;
		border-bottom-left-radius: 0.25em;
		border-bottom-right-radius: 0.25em;
		.EventCardSTFront-text {
			p {margin: 0;}
			font-size: 0.75em;
			span {font-weight: 700;}

		}
		.EventCardSTFront-options {
			margin-top: 0.6em;
			.EventCardSTFront-option {
				width: 100%;
				padding: 0;
				margin-bottom: 0.5em;
				background-color: rgba(#20353F, 0.5);
				border-radius: 0.25em;
				@include no-select();
				.EventCardSTFront-optionIcon {
					display: inline-block;
					vertical-align: middle;
					width: 2.5em;
					height: 2em;
					margin-right: 0.5em;
					svg {
						width: 100%;
						height: 100%;
						vertical-align: middle;
					}
				}
				.EventCardSTFront-optionTitle {
					display: inline-block;
					vertical-align: middle;
					font-weight: bold;
				}
				.EventCardSTFront-optionText {
					padding: 0.5em 0 0 0.25em;
					font-size: 0.75em;
				}
				&.selected {
					background-color: #213640;
				}
			}
		}
	}
}
