@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';
.ReflectionTopics {
	display: inline-block;
	vertical-align: top;
	width: 28.5em;
	margin-top: 1em;
	.ReflectionTopics-topic {
		position: relative;
		height: 2.4em;
		margin-bottom: 1.11em;
		background-color: #288E73;
		line-height: 2.4em;
		padding: 0 0.75em;
		border-radius: 0.33em;
		font-weight: bold;
		text-transform: uppercase;
		&:last-child {margin-bottom: 0;}
		cursor: pointer;
		@include no-select();
		&.disabled {
			cursor: not-allowed;
			background-color: darken(#288E73, 10%);
		}
		.ReflectionTopics-toggle {
			position: absolute;
			top: 0.45em;
			right: 0.45em;
			width: 1.5em;
			height: 1.5em;
			border: 2px solid white;
			&.selected {
				background-image: url('../../assets/images/icon-check.svg');
				background-size: 90% auto;
				background-position: center center;
				background-repeat: no-repeat;
			}

		}
	}


}