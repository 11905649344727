@import '../../../styles/variables';
@import '../../../styles/fonts';
@import '../../../styles/mixins';
@import '../../../styles/animations';
.Facilitators {
	background: rgba(#213640, 0.8);
	border-radius: 0 0.25em 0.25em 0;
	margin: 1em 0;
	.Facilitators-header {
		width: 100%;
		height: 3em;
		background-color: #213640;
		border-radius: 0.25em 0 0 0;
		@include flex('center', 'center');
		font-weight: bold;
		.Facilitators-section.reset {
			font-size: 0.85em;
			padding: 0 1em;
		}
	}

	.Facilitators-body {
		padding: 0.5em 0;
		.Facilitators-facilitator {
			width: 100%;
			@include flex('center', 'center');
			cursor: pointer;
			&:hover {
				background-color: rgba(white, 0.05);
			}
			.Facilitators-section.status {
				color: $red;
				&.enabled {color: $green;}
				&.notInvited {
					color: $yellow;
				}
			}
		}
	}

	.Facilitators-section {
		@include flex('center', 'center');
		width: 11%;
		height: 100%;
		text-align: center;
		padding: 0.3em 0.5em;
		&.name {
			width: 22%;
			@include flex('left', 'center');
		};
		&.email {
			width: 30%;
			@include flex('left', 'center');
		}
		&.reset {width: 15%;}
	}
}